import React from "react";
// Customizable Area Start
import {
  Container,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
  Box,
  Typography,
  InputLabel,
  Menu,
  MenuItem,
  Grid
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import { DatePicker as DateSelect, Modal, Select } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { Add, editImage, alarmImage, uploadIcon, deleteIcon, editIcon, copyLogo, verificationModal } from "./assets";
import { lightTheme, NextButton, BackButton, darkTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import Loader from "../../../components/src/Loader.web";
import 'react-toastify/dist/ReactToastify.css';
import Visibility from "@material-ui/icons/Visibility";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

import CustomisableUserProfilesController, {
  Props,
  IField
} from "./CustomisableUserProfilesController";
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    )
      return "number";
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    )
      return (
        <div key={index + "renderField"}>
          <div style={{ position: 'relative', top: '35px', left: '155px', marginTop: '-35px', zIndex: 99 }}>
            <Button
              data-test-id="deleteField"
              variant="text"
              style={{ color: '#DC2626', fontSize: '14px', textTransform: 'capitalize' }}
              onClick={this.state.enableCustomSection ? () => this.openDeleteModal(index, 'field', 'New Section') : () => this.openDeleteModal(index, 'field', 'Basic Info')}
              startIcon={<img src={deleteIcon} style={{ height: '16px' }} />}
            >
              Delete
            </Button>
            <Button
              data-test-id="editField"
              variant="text"
              style={{ color: '#1A469C', fontSize: '14px', textTransform: 'capitalize' }}
              onClick={() => this.editField(field.attributes)}
            >
              Edit
            </Button>
          </div>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={field.name}
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true
            }}
          // defaultValue='Enter Value'
          />
        </div>
      );

    if (field.field_type === "date" || field.field_type === "datetime")
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(this.state.profile[field.name] as string).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date: Date) => this.changeFormValue(field.name, date, field.field_type)}
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );

    if (field.field_type === "checkbox")
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.checked, field.field_type)
            }
            color='primary'
          />
          {field.title}
        </div>
      );
    if (field.field_type === "boolean")
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(field.name, event.target.value, field.field_type);
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel value={"true"} control={<Radio />} label='True' />
              <FormControlLabel value={"false"} control={<Radio />} label='False' />
            </RadioGroup>
          </FormControl>
        </div>
      );
  };

  renderFormField = (heading: string, value: string | undefined) => (
    <div className="profileAlign">
      <span className="formHeading">{heading}</span>
      <span className="formValue">{value}</span>
    </div>
  )

  editImageContainer = (open: boolean) => {
    return <ImageContainer>
      <ProfileImage style={{ marginTop: '20px' }} src={this.state.uploadPreviewImage?.toString() || this.state.profile['image']?.toString()} />
      <EditProfileImageButton
        data-test-id="openMenu"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.setState({ anchorEl: event.currentTarget })}
        startIcon={<img src={editImage} />}
      >
        Edit Profile Image
      </EditProfileImageButton>
      <Menu
        id="basic-menu"
        anchorEl={this.state.anchorEl}
        open={open}
        onClose={() => this.setState({ anchorEl: null })}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        style={{ top: '40px', left: '30px' }}
      >
        <MenuItem>
          <label htmlFor="myInput" className="profile-upload-btn" data-testid="profile-upload-btn">
            <input
              data-test-id="updateImage"
              id="myInput"
              type="file"
              accept="image/png, image/jpeg"
              ref={(reference) => this.upload = reference}
              style={{ display: "none" }}
              onChange={this.imgFileHandler}
            />
            <img src={uploadIcon} className="upload_icon" />
            <span style={{ color: '#1A469C', fontFamily: 'Rubik', fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}>
              Upload Profile Image
            </span>
          </label>
        </MenuItem>
        <MenuItem data-test-id="removeImage" onClick={this.removeImage}>
          <img src={deleteIcon} className="upload_icon" />
          <span style={{ color: '#DC2626', fontFamily: 'Rubik', fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}>
            Remove Profile Image
          </span>
        </MenuItem>
      </Menu>
      <div style={{ height: '200px', overflowY: 'auto' }}>
        <CategoryText
          data-test-id="basicInfoSection"
          onClick={this.enableBasicInfo}
          className={this.state.enableBasicInfoSection ? 'enableSection' : ''}
        >
          Basic Info
        </CategoryText>
        {this.state.profile["role_id"] == 'educator' && <CategoryText
          data-test-id="certificateSection"
          className={this.state.enableCertificateSection ? 'enableSection' : ''}
          onClick={this.enableCertificateSection}
        >
          Certificate
        </CategoryText>}
        {this.state.profile["role_id"] == 'educator' && this.state.allSection?.map((item, index) => <CategoryText
          data-test-id="customSection"
          className={(this.state.enableCustomSection && this.state.enableCustomSectionId == item.attributes.id) ? 'enableSection' : ''}
          onClick={() => this.enableCustomSection(item.attributes)}
        >
          {item.attributes.name}
          <div style={{ minWidth: '50px' }}>
            <img data-test-id="deleteSection" src={deleteIcon} onClick={() => this.openDeleteModal(item.attributes.id, "section", String(item.attributes.name))} />
            <img data-test-id="editSection" src={editIcon} onClick={() => this.editSection(item.attributes)} />
          </div>
        </CategoryText>)}
      </div>
      {this.state.profile["role_id"] == 'educator' && <EditProfileImageButton
        style={{ marginLeft: '50px', textTransform: 'capitalize' }}
        onClick={this.enableSectionModal}
        startIcon={<img src={Add} />}
        variant="text"
      >
        Add Section
      </EditProfileImageButton>}
    </ImageContainer>
  }

  imageContainer = () => {
    return <ImageContainer style={{ width: '30%' }}>
      <ProfileImage
        src={this.state.uploadPreviewImage?.toString() || this.state.profile['image']?.toString()}
        style={{ width: '100%' }}
      />
      <RoleHeading>
        {this.state.profile['role_id'] === "supervisor/manager" ? "Manager" : this.state.profile['role_id']}
      </RoleHeading>
    </ImageContainer>
  }

  editBasicInfo = () => {
    return <BasicInfoContainer>
      <EditButtonSection >
        <div>
          <EditHeading>Edit Profile</EditHeading>
          <EditDescription>here you can edit your info and tell more about yourself</EditDescription>
        </div>
        <div style={{ marginLeft: '15px' }}>
          <Button
            data-test-id={"cancelButton"}
            style={{ marginRight: "10px", color: '#DC2626', textTransform: 'capitalize', fontFamily: 'Rubik' }}
            variant='text'
            onClick={this.cancelChanges}
          >
            Cancel
          </Button>
          <SaveButton
            data-test-id={"saveButton"}
            variant='outlined'
            onClick={this.updateCustomizableProfile}
          >
            Save
          </SaveButton>
        </div>
      </EditButtonSection>
      <BasicInfoSection>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
          <BasicInfoLabel>{this.state.enableBasicInfoSection ? 'Basic Info' : this.state.enableCustomSection ? this.state.enableSectionDetails.name : 'Certificate'}</BasicInfoLabel>
          {this.state.profile["role_id"] == 'educator' && <div style={{ display: 'flex', cursor: 'pointer' }}>
            <img src={Add} style={{ width: '20px', height: '20px', marginTop: '10px' }} />
            <BasicInfoLabel
              data-test-id="addField"
              style={{ border: 'none', width: '150px', paddingLeft: '3px' }}
              onClick={this.state.enableBasicInfoSection || this.state.enableCustomSection ? this.openFieldModal : this.enableCertificateModal}
            >
              {this.state.enableBasicInfoSection || this.state.enableCustomSection ? 'Add Field' : 'Add Certificate'}
            </BasicInfoLabel>
          </div>}
        </div>
        {this.state.enableBasicInfoSection &&
          <BasicInfoCard className="scrollModal">
            <EmailTextField
              data-test-id="Email"
              label="Email"
              value={this.state.profile["email"]}
              disabled
              type='text'
              InputLabelProps={{
                shrink: true
              }}
              placeholder='Email'
              style={{ marginRight: '5px', textTransform: 'none' }}
            />
            <TextField
              data-test-id="preferred_name"
              label="Preferred Name"
              value={this.state.profile["preferred_name"]}
              onChange={(event) =>
                this.changeFormValue("preferred_name", event.target.value, 'text')
              }
              type='text'
              onKeyPress={(event) => this.onKeyPress(event, {
                field_type: 'text',
                name: "preferred_name",
                title: "",
                is_enable: false,
                is_required: false
              })}
              InputLabelProps={{
                shrink: true
              }}
              placeholder='Please Enter Preferred Name'
              style={{ marginRight: '5px' }}
            />
            <FormDiv>
              <TextField
                data-test-id="first_name"
                label="First Name"
                value={this.state.profile["first_name"]}
                onChange={(event) =>
                  this.changeFormValue("first_name", event.target.value, 'text')
                }
                type='text'
                onKeyPress={(event) => this.onKeyPress(event, {
                  field_type: 'text',
                  name: "first_name",
                  title: "",
                  is_enable: false,
                  is_required: false
                })}
                InputLabelProps={{
                  shrink: true
                }}
                placeholder='Enter your First Name'
              />
              <RightTextFiled
                data-test-id="last_name"
                label="Last Name"
                value={this.state.profile["last_name"]}
                onChange={(event) =>
                  this.changeFormValue("last_name", event.target.value, 'text')
                }
                type='text'
                onKeyPress={(event) => this.onKeyPress(event, {
                  field_type: 'text',
                  name: "last_name",
                  title: "",
                  is_enable: false,
                  is_required: false
                })}
                InputLabelProps={{
                  shrink: true
                }}
                placeholder='Enter your Last Name'
              />
            </FormDiv>
            <FormDiv>
              <TextField
                data-test-id="full_phone_number"
                label="Phone Number"
                value={this.state.profile["full_phone_number"]}
                onChange={(event) =>
                  this.changeFormValue("full_phone_number", event.target.value, 'text')
                }
                type='text'
                onKeyPress={(event) => {
                  if (!/\d/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
                placeholder='Enter phone No. with country code'
                inputProps={{
                  maxLength: 15,
                }}
              />
              <div style={{ width: '100%' }}>
                <InputLabel className="dobField">
                  Date of birth
                </InputLabel>
                <DateField
                  data-test-id='date_of_birth'
                  placement="bottomRight"
                  format="MM-DD-YYYY"
                  showToday={false}
                  placeholder="MM-DD-YYYY"
                  onChange={(event) =>
                    this.changeFormValue("date_of_birth", event, 'text')
                  }
                  disabledDate={this.disableDates}
                  defaultValue={moment(this.state.profile?.date_of_birth?.toString())}
                />
              </div>
            </FormDiv>
            <FormDiv>
              <TextField
                data-test-id="company_reference"
                label="Company Reference"
                value={this.state.profile["company_reference"]}
                onChange={(event) =>
                  this.changeFormValue("company_reference", event.target.value, 'text')
                }
                type='text'
                InputLabelProps={{
                  shrink: true
                }}
                placeholder='Enter your Company Reference'
              />
              <RightTextFiled
                data-test-id="Location"
                label="Location"
                value={this.state.profile["location"]}
                onChange={(event) =>
                  this.changeFormValue("location", event.target.value, 'text')
                }
                type='text'
                InputLabelProps={{
                  shrink: true
                }}
                placeholder='Enter your location'
              />
            </FormDiv>
            {this.state.profile["role_id"] !== 'educator' && <FormDiv>
              <RoleTextField
                data-test-id="employee_number"
                label="Employee Number"
                value={this.state.profile["employee_number"]}
                onChange={(event) =>
                  this.changeFormValue("employee_number", event.target.value, 'text')
                }
                type='text'
                InputLabelProps={{
                  shrink: true
                }}
                disabled
                placeholder="Employee Number"
              />
              <RightRoleTextField
                data-test-id="company_name"
                label="Company Name"
                value={this.state.profile["company_name"]}
                onChange={(event) =>
                  this.changeFormValue("company_name", event.target.value, 'text')
                }
                disabled
                type='text'
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Company Name"
              />
            </FormDiv>}
            <FormDiv>
              <RoleTextField
                data-test-id="role_id"
                label="Role"
                value={this.state.profile["role_id"]}
                disabled
                type='text'
                InputLabelProps={{
                  shrink: true
                }}
              />
              <RightRoleTextField
                data-test-id="completed_course"
                label="Completed Courses"
                value={this.state.profile["completed_course"]}
                type='text'
                onChange={(event) =>
                  this.changeFormValue("completed_course", event.target.value, 'text')
                }
                InputLabelProps={{
                  shrink: true
                }}
                disabled
                placeholder="Completed Course"
              />
            </FormDiv>
            <FormDiv>
              {this.state.all_field_educator?.map((element, index) => this.renderField({
                field_type: "text",
                name: String(element.attributes.description),
                title: String(element.attributes.name),
                is_enable: false,
                is_required: false,
                attributes: element.attributes
              },
                Number(element.attributes.id)))
              }
            </FormDiv>
            <FormDiv>
              {this.state.fields.map((element, index) => this.renderField(element, index))}
            </FormDiv>
          </BasicInfoCard>}
        {this.state.enableCertificateSection && <BasicInfoCard className="scrollModal">
          {this.state.allCertificates?.map((item) => <CertificateInfoCard>
            <Typography style={{ fontFamily: 'poppins', fontSize: '12px', fontWeight: 600, lineHeight: '18px' }}>
              {item.attributes.certificate_organisation}
            </Typography>
            <Typography style={{ fontFamily: 'poppins', fontSize: '12px', fontWeight: 400, lineHeight: '18px' }}>
              {item.attributes.certificate_type}
            </Typography>
            <Typography style={{ fontFamily: 'poppins', fontSize: '12px', fontWeight: 400, lineHeight: '18px' }}>
              {item.attributes.date_received}
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                data-test-id="deleteCertificate"
                variant="text"
                style={{ color: '#DC2626', fontSize: '14px', textTransform: 'capitalize' }}
                onClick={() => this.openDeleteModal(item.attributes.id, "certificate", "")}
                startIcon={<img src={deleteIcon} style={{ height: '16px' }} />}
              >
                Delete
              </Button>
              <Button
                data-test-id="editCertificate"
                variant="text"
                style={{ color: '#1A469C', fontSize: '14px', textTransform: 'capitalize' }}
                onClick={() => this.EditCertificate(item.attributes)}
              >
                Edit
              </Button>
            </div>
          </CertificateInfoCard>)}
        </BasicInfoCard>}
        {this.state.enableCustomSection && <BasicInfoCard>
          <FormDiv>
            {this.state.all_field_type?.map((element, index) => this.renderField({
              field_type: "text",
              name: String(element.description),
              title: String(element.name),
              is_enable: false,
              is_required: false,
              attributes: element
            },
              Number(element.id)))
            }
          </FormDiv>
        </BasicInfoCard>}
      </BasicInfoSection>
    </BasicInfoContainer>
  }

  basicInfo = () => {
    return <BasicInfoContainer style={{ width: '70%', margin: '20px 0px' }} className={this.state.editProfile ? '' : "editBackground"}>
      <div className="preferredNameDiv">
        <span className="preferredNameHeading">{'Preferred First Name     '}</span>
        <span className="preferredName">{this.state.profile['preferred_name']}</span>
      </div>
      <FormDiv>
        <div className="nameDiv">
          <span className="formHeading">{'First Name    '}</span>
          <span className="formValue">{this.state.profile['first_name']}</span>
        </div>
        <div className="nameDiv">
          <span className="formHeading">{'Last Name    '}</span>
          <span className="formValue">{this.state.profile['last_name']}</span>
        </div>
      </FormDiv>
      <div className="rowAlign">
        <EmailSection>
          <div style={{ display: 'flex', flexDirection: 'column', }}>
            <span className="formHeading">Email</span>
            <span className="formValue">{this.state.profile["email"]}</span>
          </div>
          <img src={copyLogo} style={{ margin: '10px', height: '24px', width: '24px', cursor: 'pointer' }} onClick={this.copyToClipboard} />
        </EmailSection>
        <div className="profileAlign">
          <span className="formHeading">{'Date of Birth'}</span>
          <span className="formValue">{moment(this.state.profile?.date_of_birth?.toString()).format('MM-DD-YYYY')}</span>
        </div>
        {this.renderFormField('Location', this.state.profile["location"]?.toString())}
      </div>
      {this.state.profile["role_id"] === "student" &&
        <div className="rowAlign">
          {this.renderFormField('Employee Num.', this.state.profile["employee_number"]?.toString())}
          {this.renderFormField('Company Name', this.state.profile["company_name"]?.toString())}
          <div className="profileAlign" style={{ borderRadius: `${this.state.profile["role_id"] === "student" ? "8px 8px 60px 8px" : ""}` }}>
            <span className="formHeading">{'Completed Cou.'}</span>
            <span className="formValue">{this.state.profile["completed_course"]}</span>
          </div>
        </div>}
      {this.state.profile["role_id"] == 'educator' &&
        <div className="employeeAlign">
          <div className="rowAlign" style={{ width: '100%' }}>
            <span className="formHeading">{'Certificate'}</span>
            <span data-test-id="seeAll" className="txtSeeAll" onClick={this.enableAllCertificateModal}>{'See All'}</span>
          </div>
          {this.state.allCertificates.length > 0 ?
            <div>
              <div className="formValue1">
                {this.state.allCertificates[0]?.attributes?.certificate_organisation}
              </div>
              <div className="formValue1" style={{ fontWeight: 400 }}>
                {this.state.allCertificates[0]?.attributes?.certificate_type}
              </div>
              <div className="formHeading" style={{ fontWeight: 400, marginTop: '3px' }}>
                {this.state.allCertificates[0]?.attributes?.date_received}
              </div>
            </div > :
            <div className="formValue1">N/A</div>}
        </div>}
      {
        this.state.profile["role_id"] == 'supervisor/manager' &&
        <div className="employeeAlign">
          <div className="rowAlign" style={{ width: '100%' }}>
            <span className="formHeading">{'Employees Profiles'}</span>
            <span data-test-id="seeAll" className="txtSeeAll" onClick={this.enableAllStudentDetails}>{'See All'}</span>
          </div>
          {this.state.allStudentsProfile && <div className="employeeDetail">
            <div className="profileEmployeeAlign">
              <img src={this.state.allStudentsProfile[0]?.attributes?.image?.toString()} className="profileAvatar" />
            </div>
            <div className="profileEmployeeAlign">
              <span className="formHeading">{'Preferred Name'}</span>
              <span className="formValue1">{this.state.allStudentsProfile[0]?.attributes?.preferred_name}</span>
            </div>
            <div className="profileEmployeeAlign">
              <span className="formHeading">Role</span>
              <span className="formValue1">{this.state.allStudentsProfile[0]?.attributes?.role_id}</span>
            </div>
            <div className="profileEmployeeAlign">
              <span className="formHeading">Group</span>
              <span className="formValue1">{this.state.allStudentsProfile[0]?.attributes?.group_name}</span>
            </div>
            <div className="profileEmployeeAlign">
              <span className="formHeading">Email</span>
              <span className="formValue1">{this.state.allStudentsProfile[0]?.attributes?.email}</span>
            </div>
          </div>}
        </div>
      }
    </BasicInfoContainer >
  }

  passParamsCondition = (state: boolean, value1: string, value2: string) => (
    state ? value1 : value2
  )

  addFieldDisable = () => {
    if (this.state.enableCustomSection) {
      return !this.state.field_type['description'] || !this.state.field_type['name']
    } else {
      return !this.state.field_educator["description"] || !this.state.field_educator["name"]
    }
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const open = Boolean(this.state.anchorEl);
    return (
      <ThemeProvider theme={this.state.enableDarkMode ? darkTheme : lightTheme}>
        <HamburgerMenu navigation={this.props.navigation} handleMenu={this.handleMenu} open={this.state.sideMenuOpen} handleTheme={this.handleTheme} themeDark={this.state.enableDarkMode} />
        <HeadContainer style={{ paddingLeft: this.state.sideMenuOpen ? 273 : 107, paddingRight: 48, transition: 'all 300ms linear' }}>
          <Loader loading={this.state.loading} />
          {!this.state.editProfile &&
            <EditButtonSection style={{ width: '100%', maxWidth: '1070px', alignSelf: 'center', padding: '30px' }}>
              <div>
                <EditHeading>My Profile</EditHeading>
                <EditDescription>Here you can edit your info and tell more about yourself</EditDescription>
              </div>
              <div style={{ marginLeft: '15px', width: '150px' }}>
                <EditProfileButton
                  data-test-id={"editProfileButton"}
                  variant='outlined'
                  onClick={this.enableEditProfile}
                  style={{ width: '100%' }}
                >
                  Edit Profile
                </EditProfileButton>
              </div>
            </EditButtonSection>}
          <OuterSection>
            <InnerSection>
              <MainContainer style={{ borderRadius: "8px 8px 60px 8px" }} className={this.state.editProfile ? '' : "editBackground"}>
                {this.state.editProfile ?
                  this.editImageContainer(open)
                  :
                  this.imageContainer()
                }

                {this.state.editProfile ?
                  this.editBasicInfo()
                  :
                  this.basicInfo()
                }
              </MainContainer>
            </InnerSection>
          </OuterSection>
          <FieldModal
            open={this.state.fieldModal}
            onCancel={this.closeBothModal}
            title={
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <ModalHeading variant="h1">{this.state.enableEditField ? "Edit " : "Add "}Field</ModalHeading>
                <ModalLine style={{ width: '150px' }} />
                <ModalSubHead variant="h4">Basic info</ModalSubHead>
                <ModalLine style={{ width: '150px' }} />
              </div>}
            centered
            closable={false}
            footer={null}
            destroyOnClose
            width={766}
            style={{ paddingBottom: '40px' }}
          >
            <TextField
              data-test-id="field_name"
              label="Field Name"
              value={this.state.enableCustomSection ? this.state.field_type["name"] : this.state.field_educator["name"]}
              type='text'
              onChange={(event) =>
                this.changeFieldValue("name", event.target.value)
              }
              InputLabelProps={{
                shrink: true
              }}
              placeholder='Enter Your Field Name'
            />
            <TextField
              data-test-id="field_description"
              label="Field Description"
              value={this.state.enableCustomSection ? this.state.field_type["description"] : this.state.field_educator["description"]}
              onChange={(event) =>
                this.changeFieldValue("description", event.target.value)
              }
              type='text'
              InputLabelProps={{
                shrink: true
              }}
              placeholder='Please Enter Field Description'
            />
            <BackButton onClick={this.openCancelModal}>Cancel</BackButton>
            <NextButton
              data-test-id="addFieldButton"
              style={{ width: '65%', marginLeft: '10px' }}
              onClick={this.handleEditButton}
              disabled={this.addFieldDisable()}
            >
              {this.passParamsCondition(this.state.enableEditField, "Save", "Add")}
            </NextButton>
            <ToastContainer containerId="D" />
          </FieldModal>
          <FieldModal
            open={this.state.cancelModal}
            onCancel={this.closeCancelModal}
            title={
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <ModalHeading variant="h3">Not Saved</ModalHeading>
                <ModalLine style={{ width: '65px' }} />
                <ModalSubHead variant="subtitle1">Basic info</ModalSubHead>
                <ModalLine style={{ width: '65px' }} />
              </div>}
            centered
            closable={false}
            footer={null}
            destroyOnClose
            width={531}
            style={{ height: '309px', padding: '30px' }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img src={alarmImage} style={{ width: "112px", height: "112px", marginRight: '10px' }} />
              <CancelModalDescription variant="body1">
                Are you sure you want to cancel this? The information you provided will be permanently deleted.
              </CancelModalDescription>
            </div>

            <BackButton onClick={this.closeCancelModal}>Cancel</BackButton>
            <NextButton onClick={this.closeBothModal} style={{ width: '65%', color: '#F8FAFC', marginLeft: '10px' }}>Delete</NextButton>
          </FieldModal>
          <FieldModal
            open={this.state.certificateModal}
            onCancel={this.closeBothModal}
            title={
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <ModalHeading variant="h3">Certificate</ModalHeading>
                <ModalLine style={{ width: '150px' }} />
                <ModalSubHead variant="subtitle2">{this.state.enableEditCertificate ? "Edit " : "Add "}Certificate</ModalSubHead>
                <ModalLine style={{ width: '150px' }} />
              </div>
            }
            centered
            closable={false}
            footer={null}
            destroyOnClose
            width={766}
            style={{ paddingBottom: '40px' }}
          >
            <FormDiv>
              <TextField
                data-test-id="certificate_organisation"
                label="Certificates Organization"
                value={this.state.certificate["certificate_organisation"]}
                onChange={(event) =>
                  this.changeCertificateValue("certificate_organisation", event.target.value)
                }
                type='text'
                InputLabelProps={{
                  shrink: true
                }}
                placeholder='Enter your School or University'
              />
              <TextField
                data-test-id="website"
                label="Website"
                value={this.state.certificate["website"]}
                onChange={(event) =>
                  this.changeCertificateValue("website", event.target.value)
                }
                type='text'
                InputLabelProps={{
                  shrink: true
                }}
                placeholder='Enter Website URL'
                error={this.state.webCertificateError}
                helperText={this.state.webCertificateError ? "Field must be a URL starting with http:// or www." : ""}
                style={{ textTransform: 'none' }}
              />
            </FormDiv>
            <TextField
              data-test-id="certificate_type"
              label="Certificates"
              value={this.state.certificate["certificate_type"]}
              onChange={(event) =>
                this.changeCertificateValue("certificate_type", event.target.value)
              }
              type='text'
              onKeyPress={(event) => this.onKeyPress(event, {
                field_type: 'text',
                name: "certificate_type",
                title: "",
                is_enable: false,
                is_required: false
              })}
              InputLabelProps={{
                shrink: true
              }}
              placeholder='Ex : UI/IX Designer'
            />
            <TextField
              data-test-id="date_received"
              label="Date Received"
              value={this.state.certificate["date_received"]}
              onChange={(event) =>
                this.changeCertificateValue("date_received", event.target.value)
              }
              type='text'
              InputLabelProps={{
                shrink: true
              }}
              placeholder='Enter Received Year'
              onKeyPress={(event) => this.onKeyPress(event, {
                field_type: 'number',
                name: "certificate_type",
                title: "",
                is_enable: false,
                is_required: false
              })}
              inputProps={{
                maxLength: 4
              }}
            />
            <TextField
              data-test-id="description"
              label="Description"
              multiline
              value={this.state.certificate["description"]}
              onChange={(event) =>
                this.changeCertificateValue("description", event.target.value)
              }
              type='text'
              InputLabelProps={{
                shrink: true
              }}
              placeholder='Enter your Description'
            />
            <BackButton onClick={this.openCancelModal}>Cancel</BackButton>
            <NextButton
              data-test-id="editCertificateButton"
              onClick={this.state.enableEditCertificate ? () => this.editAddedCertificate(Number(this.state.certificate["id"])) : this.addCertificate}
              style={{ width: '65%', marginLeft: '10px' }}
              disabled={!this.state.certificate["website"]}
            >
              {this.passParamsCondition(this.state.enableEditCertificate, "Save", "Add")}
            </NextButton>
            <ToastContainer containerId="A" position="top-right" />
          </FieldModal>
          <FieldModal
            data-test-id="closeBothModal"
            open={this.state.sectionModal}
            onCancel={this.closeBothModal}
            title={
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <ModalHeading variant="h3" style={{ textAlign: 'center' }}>
                  {this.passParamsCondition(this.state.enableEditSection, "Edit ", "Add ")}Section
                </ModalHeading>
              </div>}
            centered
            closable={false}
            footer={null}
            destroyOnClose
            width={766}
            style={{ height: '304px' }}
          >
            <TextField
              data-test-id="section_name"
              label="Section Name"
              value={this.state.section["name"]}
              onChange={(event) =>
                this.changeSectionValue("name", event.target.value)
              }
              type='text'
              InputLabelProps={{
                shrink: true
              }}
              placeholder='Enter Section Name'
            />
            <BackButton onClick={this.openCancelModal}>Cancel</BackButton>
            <NextButton
              data-test-id="addSectionButton"
              onClick={this.state.enableEditSection ? () => this.editAddedSection(this.state.section["id"]) : this.addSection}
              style={{ width: '65%', marginLeft: '10px' }}
              disabled={!this.state.section["name"]}
            >
              {this.passParamsCondition(this.state.enableEditSection, "Save", "Add")}
            </NextButton>
            <ToastContainer containerId="B" position="top-right" />
          </FieldModal>
          <FieldModal
            data-test-id='allStudentModal'
            open={this.state.allStudentModal}
            onCancel={this.closeAllStudentModal}
            title={<ModalHeading variant="h3" style={{ textAlign: 'left' }}>Employees Profiles</ModalHeading>}
            centered
            closable={true}
            footer={null}
            destroyOnClose
            width={766}
            style={{ height: '477px' }}
          >
            <ModalLine style={{ width: '100%', height: 0, marginTop: 0 }} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ModalSubHead variant='body1' style={{ fontWeight: 400, lineHeight: '24px' }}>
                Filter by:</ModalSubHead>
              <Select
                data-test-id="filterValue"
                onChange={(event) => this.changeFilterValue(event)}
                placeholder="Group"
              >
                {this.state.allGroupName?.map((item) => <Select.Option value={item.group_name}>
                  {item.group_name}
                </Select.Option>)}
              </Select>
            </div>
            <Grid container spacing={0} className="scrollModal">
              {this.state.allStudentsProfile?.map((item) => <div className="employeeDetail">
                <Grid item xs={'auto'} className="profileEmployeeAlign">
                  <img src={item.attributes?.image?.toString()} className="profileAvatar" />
                </Grid>
                <Grid item xs={2} className="profileEmployeeAlign">
                  <span className="formHeading">{'Preferred Name'}</span>
                  <span className="formValue1" style={{ fontWeight: 500 }}>{item.attributes?.preferred_name}</span>
                </Grid>
                <Grid item xs={1} className="profileEmployeeAlign">
                  <span className="formHeading">Role</span>
                  <span className="formValue1" style={{ fontWeight: 500 }}>{item.attributes?.role_id}</span>
                </Grid>
                <Grid item xs={1} className="profileEmployeeAlign">
                  <span className="formHeading">Company</span>
                  <span className="formValue1">{item.attributes?.company_name}</span>
                </Grid>
                <Grid item xs={2} className="profileEmployeeAlign">
                  <span className="formHeading">Group</span>
                  <span className="formValue1">{item.attributes?.group_name}</span>
                </Grid>
                <Grid item xs={5} className="profileEmployeeAlign">
                  <span className="formHeading" style={{ minWidth: '285px' }}>Email</span>
                  <span className="formValue1" style={{ fontWeight: 500 }}>{item.attributes?.email}</span>
                </Grid>
                <Grid item xs={'auto'} className="profileEmployeeAlign">
                  <Visibility
                    data-test-id='editSingleProfile'
                    className="profileAvatar" style={{ cursor: 'pointer', height: '21px' }}
                    onClick={() => this.editEmployeeProfile(item.attributes)}
                  />
                </Grid>
              </div>)}
            </Grid>
          </FieldModal>
          <FieldModal
            open={this.state.openEmployeeEditModal}
            onCancel={() => this.setState({ openEmployeeEditModal: false })}
            title={
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <ModalHeading variant="h3">Employee Profile</ModalHeading>
                <ModalLine style={{ width: '35px' }} />
                <ModalSubHead variant="subtitle1" style={{ width: '165px', overflowX: "auto", textAlign: 'center', height: '40px' }}>
                  {this.state.selectedEmployeeDetail.preferred_name}
                </ModalSubHead>
                <ModalLine style={{ width: '35px' }} />
              </div>}
            centered
            closable={true}
            footer={null}
            destroyOnClose
            width={766}
            style={{ height: '506px' }}
          >
            <TextField
              disabled
              data-test-id="employee_email"
              label="Email"
              value={this.state.selectedEmployeeDetail["email"]}
              onChange={(event) =>
                this.changeSelectedEmployeeValue("email", event.target.value)
              }
              type='text'
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              data-test-id="employee_role"
              label="Role"
              value={this.state.selectedEmployeeDetail["role_id"]}
              onChange={(event) =>
                this.changeSelectedEmployeeValue("role_id", event.target.value)
              }
              type='text'
              InputLabelProps={{
                shrink: true
              }}
              disabled
              style={{ textTransform: 'capitalize' }}
            />
            <TextField
              data-test-id="employee_preferred_name"
              label="Preferred name"
              value={this.state.selectedEmployeeDetail["preferred_name"]}
              onChange={(event) =>
                this.changeSelectedEmployeeValue("preferred_name", event.target.value)
              }
              type='text'
              InputLabelProps={{
                shrink: true
              }}
              placeholder='Preferred Name'
              disabled
            />
            <FormDiv>
              <TextField
                data-test-id="employee_company_name"
                label="Company Name"
                value={this.state.selectedEmployeeDetail["company_name"]}
                onChange={(event) =>
                  this.changeSelectedEmployeeValue("company_name", event.target.value)
                }
                type='text'
                InputLabelProps={{
                  shrink: true
                }}
                placeholder='Company Name'
                disabled
              />
              <TextField
                data-test-id="Employee_number"
                label="Employee Number"
                value={this.state.selectedEmployeeDetail["employee_number"]}
                onChange={(event) =>
                  this.changeSelectedEmployeeValue("employee_number", event.target.value)
                }
                type='text'
                InputLabelProps={{
                  shrink: true
                }}
                placeholder='Employee Number'
                disabled
              />
            </FormDiv>
            <ToastContainer position="top-right" containerId='C' />
          </FieldModal>
          <FieldModal
            open={this.state.confirmModal}
            onCancel={this.closeBothModal}
            centered
            closable={true}
            footer={null}
            destroyOnClose
            width={696}
            style={{ height: '430px' }}
          >
            <div style={{ display: 'flex', justifyContent: "center", padding: '20px', flexDirection: 'column', alignItems: 'center' }}>
              <img src={verificationModal} alt="verificationModal" style={{ height: '176px' }} />
              <EditHeading>Edited Successfully</EditHeading>
            </div>
            <NextButton
              data-test-id="confirmModalButton"
              onClick={this.closeBothModal}
              fullWidth
              style={{ color: '#F8FAFC' }}
            >
              Finish
            </NextButton>
            <Typography
              data-test-id="closeConfirmModal"
              style={{ textAlign: 'center', fontSize: '14px', color: '#1A469C', marginTop: '15px', fontFamily: 'Rubik', cursor: 'pointer' }}
              onClick={this.closeConfirmModal}
            >
              Back to Employees Profiles
            </Typography>
          </FieldModal>
          <FieldModal
            open={this.state.allCertificateModal}
            onCancel={this.closeCertificateModal}
            title={<ModalHeading variant="h3">Certificates</ModalHeading>}
            centered
            closable={true}
            footer={null}
            width={766}
            style={{ height: '477px' }}
          >
            <Grid container spacing={0} className="scrollModal">
              {this.state.allCertificates.map((item, index) => (
                <div className="employeeDetail" style={{ minWidth: '98%', height: "100px", padding: '36px 24px' }}>
                  <Grid item xs={1} className="profileEmployeeAlign">
                    <div className="formValue1" style={{ fontWeight: 500, fontSize: '14px' }}>{++index}</div>
                  </Grid>
                  <Grid item xs={5} className="profileEmployeeAlign">
                    <div className="formValue1" style={{ fontWeight: 500, fontSize: '13px' }}>{item.attributes?.certificate_organisation}</div>
                    <div className="formValue1" style={{ fontWeight: 400, fontSize: '12px' }}>{item.attributes?.certificate_type}</div>
                  </Grid>
                  <Grid item xs={4} className="profileEmployeeAlign">
                    <div className="formHeading" style={{ textAlign: 'center' }}>{item.attributes?.date_received}</div>
                  </Grid>
                </div>
              ))}
            </Grid>
            <BlurDiv />
          </FieldModal>
          <FieldModal
            open={this.state.deleteModal}
            onCancel={this.closeDeleteModal}
            title={
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                {this.state.deleteType === 'certificate' ? <ModalHeading variant="h3">Delete {this.state.deleteType}</ModalHeading> :
                  <>
                    <ModalHeading variant="h3">Delete {this.state.deleteType}</ModalHeading>
                    <ModalLine style={{ width: '60px' }} />
                    <ModalSubHead variant="subtitle1" style={{ maxWidth: '125px', overflowX: "auto" }}>{this.state.deleteModalSub}</ModalSubHead>
                    <ModalLine style={{ width: '60px' }} />
                  </>}
              </div>}
            centered
            closable={false}
            footer={null}
            destroyOnClose
            width={611}
            style={{ height: '356px', padding: '40px' }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img src={alarmImage} style={{ width: "112px", height: "112px", marginRight: '10px' }} />
              <ModalSubHead variant="body1">Are you sure you want to delete this? The information you provided will be permanently deleted.</ModalSubHead>
            </div>
            <BackButton data-test-id="closeDeleteModal" onClick={this.closeDeleteModal}>Cancel</BackButton>
            <NextButton data-test-id='closeBothModalCall' onClick={this.closeBothModalCall} style={{ width: '65%', color: '#F8FAFC', marginLeft: '10px' }}>Delete</NextButton>
          </FieldModal>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </HeadContainer>
      </ThemeProvider >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const HeadContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%'
}))

export const FormDiv = styled(Box)(({ theme }) => ({
  display: 'inline-grid',
  gridTemplateColumns: ' 1fr 1fr',
  flexDirection: 'row',
  width: '100%',
  "& .MuiButton-startIcon": {
    marginRight: '0px'
  },
  "& .dobField": {
    color: theme.palette.text.primary,
    fontSize: '12px',
    textAlign: 'left',
    fontFamily: 'Rubik',
    fontWeight: 400,
    lineHeight: '22px',
    marginLeft: '5px',
    position: 'relative',
    top: 5
  }
}))

const OuterSection = styled(Box)(({ theme }) => ({
  background: theme.palette.action.focus,
  width: '100%',
  maxWidth: '1070px',
  alignSelf: 'center',
  borderRadius: "8px 8px 50px 8px",
  maxHeight: '698px',
  padding: '0px 3px 3px 0px'
}))
const InnerSection = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  width: '100%',
  maxWidth: '1070px',
  alignSelf: 'center',
  borderRadius: "0px 0px 80px 0px",
  maxHeight: '678px',
  padding: '0px 20px 20px 0px',
  "& .editBackground": {
    background: theme.palette.action.focus
  }
}))

export const MainContainer = styled(Container)(({ theme }) => ({
  width: '100%',
  maxWidth: '1070px',
  maxHeight: '658px',
  display: 'flex',
  flexDirection: 'row',
  "& .ant-select-dropdown": {
    width: 'auto !important',
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: '12px',
    minWidth: "200px",
    background: theme.palette.action.focus
  }
}))

const ImageContainer = styled(Container)(({ theme }) => ({
  width: '30%',
  maxWidth: '364px',
  maxHeight: '658px',
  borderRadius: '18px',
  background: theme.palette.action.focus,
  marginTop: '20px',
  "& .MuiButton-startIcon": {
    marginRight: '2px'
  },
  "& .enableSection": {
    background: theme.palette.background.paper,
    borderRadius: '30px',
    color: theme.palette.info.contrastText,

  }
}))

const CategoryText = styled(Typography)(({ theme }) => ({
  padding: '16px 20px 16px 36px',
  fontFamily: 'Rubik',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '26px',
  textAlign: 'left',
  color: theme.palette.info.light,
  cursor: 'pointer',
  borderRadius: '30px',
  wordWrap: 'break-word',
  overflowX: 'auto',
  display: 'flex',
  justifyContent: 'space-between'
}))

const ProfileImage = styled('img')(({ theme }) => ({
  marginTop: '5px',
  borderRadius: '18px',
  background: theme.palette.action.focus,
  width: '100%',
  height: '215px'
}))

const EditProfileImageButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  marginLeft: '10%',
  textTransform: 'capitalize',
  fontSize: '16px',
  fontWeight: 500,
  fontFamily: 'Rubik',
  '& .MuiButton-label': {
    fontFamily: 'Rubik',
    color: theme.palette.info.contrastText
  },
  '.MuiMenuItem-root': {
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px'
  },
  '& .MuiPopover-paper': {
    background: theme.palette.background.default
  }
}))

const BasicInfoContainer = styled(Container)(({ theme }) => ({
  width: '70%',
  maxWidth: '686px',
  maxHeight: '621px',
  borderRadius: '18px',
  background: theme.palette.background.default,
  "& .profileAlign": {
    display: 'flex',
    flexDirection: 'column',
    height: '60px',
    padding: '10px 16px',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.info.dark}`,
    margin: '5px',
    width: '300px',
    background: theme.palette.primary.dark
  },
  "& .profileEmployeeAlign": {
    display: 'flex',
    flexDirection: 'column',
    height: '38px',
    margin: '5px',
  },
  "& .rowAlign": {
    display: 'flex',
    justifyContent: 'space-between'
  },
  "& .employeeAlign": {
    display: 'flex',
    height: 'auto',
    padding: '10px 16px',
    border: `1px solid ${theme.palette.info.dark}`,
    margin: '5px',
    borderRadius: "8px 8px 60px 8px",
    flexDirection: 'column',
    background: theme.palette.primary.dark
  },
  "& .profileAvatar": {
    width: '34px',
    height: '34px',
    borderRadius: '35.25px'
  },
  "& .nameDiv": {
    width: '96%',
    height: '41px',
    padding: '6px 31px',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.info.dark}`,
    margin: '5px',
    overflowX: 'auto',
    background: theme.palette.primary.dark
  },
  "& .preferredNameDiv": {
    width: '98%',
    maxWidth: '738px',
    height: '93px',
    padding: '16px 24px 16px 24px',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.info.dark}`,
    margin: '5px',
    overflowX: 'auto',
    background: theme.palette.primary.dark
  },
  "& .preferredNameHeading": {
    fontFamily: 'Rubik',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '23.7px',
    textAlign: 'left',
    color: theme.palette.info.light
  },
  "& .preferredName": {
    fontFamily: 'Rubik',
    fontSize: '36px',
    fontWeight: 400,
    lineHeight: '54px',
    textAlign: 'left',
    marginLeft: '20px',
    color: theme.palette.info.main
  },
  "& .formHeading": {
    fontFamily: 'Rubik',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14.22px',
    textAlign: 'left',
    color: theme.palette.info.light
  },
  "& .txtSeeAll": {
    fontFamily: 'Rubik',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    color: theme.palette.info.contrastText,
    cursor: 'pointer'
  },
  "& .employeeDetail": {
    height: 'auto',
    borderRadius: '8px 8px 60px 8px',
    border: `0.5px solid ${theme.palette.secondary.dark}`,
    display: 'flex',
    paddingTop: '5px'
  },
  "& .formValue": {
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24.47px',
    textAlign: 'left',
    color: theme.palette.info.main,
    wordBreak: 'break-all',
    overflowX: 'auto'
  },
  "& .formValue1": {
    fontFamily: 'Rubik',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '18px',
    textAlign: 'left',
    color: theme.palette.info.main,
    wordBreak: 'break-all',
    overflowX: 'auto'
  }
}))

export const EditButtonSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '18px',
  background: theme.palette.action.focus,
  padding: '15px',
  maxWidth: '686px',
  maxHeight: '113px',
  margin: '20px 0px',
  justifyContent: 'space-between'
}))

const BasicInfoSection = styled(Box)(({ theme }) => ({
  maxWidth: '646px',
  maxHeight: '488px',
  borderRadius: '16.25px 16.25px 90.3px 16.25px',
  background: theme.palette.action.focus,
  ".curveBorder": {
    borderRadius: '8px, 8px, 30px, 8px'
  },
  "& .MuiFormControl-root": {
    width: '100%',
    margin: '5px'
  },
  "& .MuiInputLabel-formControl": {
    fontFamily: 'Rubik',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    color: theme.palette.text.primary,
    marginLeft: '5px'
  },
  "& .MuiInput-underline::before": {
    borderBottom: 'none'
  },
  "& .MuiInput-underline::after": {
    borderBottom: 'none'
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
    borderBottom: 'transparent'
  },
  "& .MuiInputBase-input": {
    padding: '10px 12px 10px 16px',
    gap: '4px',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.info.dark}`,
    color: theme.palette.info.light,
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    margin: '5px',
    marginRight: '15px',
    background: theme.palette.background.default
  },
  "& .centerAlign": {
    textAlign: 'center'
  },
  "& .scrollModal": {
    overflowY: 'scroll'
  },
  "& .scrollModal::-webkit-scrollbar-track": {
    background: 'transparent',
    borderRadius: '30px 30px 90px 30px',
    border: `1px solid ${theme.palette.info.dark}`
  },
  "& .scrollModal::-webkit-scrollbar": {
    width: '13px',
    borderRadius: '30px 30px 90px 30px',
  },
  "& .scrollModal::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.background.paper,
    width: '13px',
    height: '43px',
    borderRadius: '30px 30px 90px 30px',
    border: '1px solid #94A3B8',
  },
}))

const BasicInfoLabel = styled(Typography)(({ theme }) => ({
  height: '40px',
  padding: '10px 12px 10px 12px',
  gap: '6px',
  borderRadius: '18px 18px 0px 18px',
  borderBottom: `1px solid ${theme.palette.info.contrastText}`,
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  textAlign: 'left',
  color: theme.palette.info.contrastText
}))

const SaveButton = styled(Button)(({ theme }) => ({
  gap: '8px',
  borderRadius: '30px',
  padding: '10px 16px 10px 16px',
  backgroundColor: theme.palette.action.active,
  fontFamily: "Rubik",
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  color: theme.palette.text.secondary,
  textTransform: 'capitalize',
  boxShadow: 'none',
  maxHeight: '44px'
}))

export const EditHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '30px',
  fontWeight: 500,
  lineHeight: '30px',
  letterSpacing: '0.41111114621162415px',
  textAlign: 'left',
  color: theme.palette.secondary.main
}))

export const EditDescription = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '14px',
  letterSpacing: '0.4px',
  textAlign: 'left',
  marginTop: '5px',
  color: theme.palette.secondary.main
}))

const BasicInfoCard = styled(Box)({
  paddingTop: 0,
  width: '100%',
  maxWidth: '665px',
  height: '346px',
  overflowY: 'scroll',
  padding: '20px',
  overflowX: 'hidden',
})

const EmailTextField = styled(TextField)(({ theme }) => ({
  background: theme.palette.action.focus,
  "& .MuiInputBase-input": {
    padding: '10px 12px 10px 16px',
    gap: '4px',
    borderRadius: '8px',
    border: `1.5px solid ${theme.palette.info.contrastText}`,
    color: theme.palette.info.main,
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    margin: '5px',
    textTransform: 'none',
    marginRight: '15px',
    background: theme.palette.action.focus
  },
  "& .MuiInputLabel-formControl": {
    color: theme.palette.text.primary
  }
}))

const RoleTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: '10px 12px 10px 16px',
    gap: '4px',
    borderRadius: '8px',
    border: `1.5px solid ${theme.palette.info.contrastText}`,
    color: theme.palette.info.main,
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',
    margin: '5px',
    marginRight: '15px',
    background: theme.palette.action.focus
  },
  "& .MuiInputLabel-formControl": {
    color: theme.palette.text.primary
  }
}))

const RightRoleTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: '10px 12px 10px 16px',
    gap: '4px',
    borderRadius: '8px 8px 30px 8px',
    color: theme.palette.info.main,
    border: `1.5px solid ${theme.palette.info.contrastText}`,
    fontSize: '16px',
    fontFamily: 'Rubik',
    lineHeight: '24px',
    fontWeight: 400,
    margin: '5px',
    textAlign: 'center',
    marginRight: '15px',
    textTransform: 'none',
    background: theme.palette.action.focus
  },
  "& .MuiInputLabel-formControl": {
    color: theme.palette.text.primary
  }
}))

const RightTextFiled = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: '10px 12px 10px 16px',
    gap: '4px',
    borderRadius: '8px 8px 30px 8px',
    border: `1px solid ${theme.palette.info.dark}`,
    color: theme.palette.info.light,
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    margin: '5px',
    background: theme.palette.background.default,
    marginRight: '15px'
  },
  "& .MuiInputLabel-formControl": {
    color: theme.palette.text.primary
  }
}))

const DateField = styled(DateSelect)(({ theme }) => ({
  background: theme.palette.background.default,
  width: '95%',
  padding: '10px 12px 10px 16px',
  borderRadius: '8px 8px 30px 8px',
  height: '41.4px',
  border: `1px solid ${theme.palette.info.dark}`,
  color: theme.palette.info.light,
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  textAlign: 'left',
  margin: '5px',
  marginLeft: '10px',
  '& .ant-picker-input > input': {
    color: theme.palette.info.light,
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
  },
  '& .ant-picker-suffix': {
    display: 'none'
  },
  '& .ant-picker-input:hover .ant-picker-clear': {
    opacity: 1,
    display: 'none !important'
  },
  '& .ant-picker-focused': {
    boxShadow: 'none'
  }
}))

export const FieldModal = styled(Modal)(({ theme }) => ({
  padding: '48px',
  gap: '24px',
  borderRadius: '30px',
  border: `1px solid ${theme.palette.background.default}`,
  background: theme.palette.action.focus,
  "& .ant-modal-header": {
    background: theme.palette.action.focus,
    alignItems: 'center',
    borderBottom: 'none'
  },
  "& .ant-modal-body": {
    padding: 0,
    background: theme.palette.action.focus,
    "& .selectedOption": {
      color: '#F8FAFC !important',
      background: '#1A469C !important',
    },
    "& .scrollModal": {
      overflowY: 'scroll',
      height: '40vh'
    },
    "& .scrollModal::-webkit-scrollbar-track": {
      borderRadius: '30px 30px 90px 30px',
      background: 'transparent',
      border: `1px solid ${theme.palette.info.dark}`
    },
    "& .scrollModal::-webkit-scrollbar": {
      borderRadius: '30px 30px 90px 30px',
      width: '13px'
    },
    "& .scrollModal::-webkit-scrollbar-thumb": {
      border: '1px solid #94A3B8',
      borderRadius: '30px 30px 90px 30px',
      width: '13px',
      height: '43px',
      backgroundColor: theme.palette.background.paper
    },
    '& .MuiButton-contained:hover': {
      backgroundColor: theme.palette.action.selected
    },
    '& .MuiButton-root:hover': {
      color: "#FFF",
      backgroundColor: theme.palette.action.selected
    },
    '& .sendButton': {
      color: "#FFF",
      backgroundColor: theme.palette.action.selected
    },
  },
  "& .ant-select-arrow": {
    top: 0
  },
  "& .ant-select": {
    width: "auto",
    height: '42px',
    fontFamily: 'Rubik',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    color: theme.palette.secondary.main
  },
  "& .ant-select:not(.ant-select-customize-input) .ant-select-selector": {
    borderRadius: "8px",
    minWidth: '150px',
    height: '44px',
    padding: '8px 16px',
    background: theme.palette.action.focus,
    borderColor: theme.palette.info.dark
  },
  "& .ant-modal-content": {
    boxShadow: 'none'
  },
  "& .MuiFormControl-root": {
    margin: '5px',
    width: '100%',
  },
  "& .MuiInputLabel-formControl": {
    textAlign: 'left',
    marginLeft: '5px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontFamily: 'Rubik',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
    borderBottom: 'transparent'
  },
  "& .MuiInput-underline::before": {
    borderBottom: 'none'
  },
  "& .MuiInputBase-input": {
    border: `1px solid ${theme.palette.info.dark}`,
    gap: '4px',
    padding: '10px 12px 10px 16px',
    borderRadius: '8px',
    fontWeight: 400,
    fontFamily: 'Rubik',
    color: theme.palette.info.light,
    fontSize: '16px',
    margin: '5px',
    textAlign: 'left',
    lineHeight: '24px',
    marginRight: '15px',
    background: theme.palette.background.default
  },
  "& .MuiInput-underline::after": {
    borderBottom: 'none'
  },
  "& .employeeDetail": {
    height: 'auto',
    border: `0.5px solid ${theme.palette.secondary.dark}`,
    borderRadius: '8px',
    paddingTop: '5px',
    display: 'flex',
    marginTop: '5px',
    background: theme.palette.background.default,
    minWidth: '1000px'
  },
  "& .profileEmployeeAlign": {
    flexDirection: 'column',
    display: 'flex',
    margin: '5px',
    height: '38px',
  },
  "& .formHeading": {
    fontSize: '12px',
    fontFamily: 'Rubik',
    lineHeight: '14.22px',
    fontWeight: 400,
    color: theme.palette.info.light,
    textAlign: 'left',
  },
  "& .formValue1": {
    fontSize: '14px',
    fontFamily: 'Rubik',
    lineHeight: '18px',
    fontWeight: 700,
    color: theme.palette.info.main,
    textAlign: 'left',
    wordBreak: 'break-all',
    overflowX: 'auto'
  },
  "& .profileAvatar": {
    width: '34px',
    borderRadius: '35.25px',
    height: '34px',
    fill: theme.palette.action.selected
  },
  "& .anticon": {
    position: 'relative',
    top: '21px',
    color: theme.palette.info.contrastText
  },
  "& .ant-select-arrow .anticon": {
    color: theme.palette.info.light
  },
  "& .ant-modal-close-x": {
    textAlign: 'right'
  },
  "& .MuiFormHelperText-root": {
    color: "#DC2626"
  },
  "& .otpInputStyle": {
    width: '38.9px !important',
    height: '48.09px',
    padding: '12.04px',
    borderRadius: '9.63px',
    border: `1.2px solid ${theme.palette.info.dark}`,
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    background: theme.palette.background.default,
    color: theme.palette.secondary.main
  },
  "& .MuiButton-root.Mui-disabled": {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.text.secondary
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.info.contrastText
  },
  '& .MuiFormControlLabel-labelPlacementStart': {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.action.disabled}`,
    padding: '10px 0px'
  },
  '& .ant-radio-inner': {
    height: '20px',
    width: '20px',
    border: `1.2px solid ${theme.palette.secondary.contrastText}`,
    backgroundColor: theme.palette.primary.main
  },
  '& .ant-radio-checked .ant-radio-inner': {
    border: `10px solid ${theme.palette.info.contrastText}`,
    height: '24px',
    width: '24px'
  },
  '& .ant-radio-checked .ant-radio-inner::after': {
    backgroundColor: '#FFF'
  }
}))

export const ModalHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.info.main
}))

export const ModalLine = styled('hr')(({ theme }) => ({
  height: '1px',
  background: theme.palette.action.disabled
}))

export const ModalSubHead = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.info.main
}))

const CancelModalDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontFamily: 'Rubik',
  fontWeight: 500,
}))

const CertificateInfoCard = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '104px',
  padding: '16px',
  borderRadius: '18px',
  border: `0.5px solid ${theme.palette.text.hint}`,
  marginTop: '10px',
  background: theme.palette.background.default,
  "& .MuiTypography-body1": {
    color: theme.palette.info.main
  }
}))

const RoleHeading = styled('span')(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '30px',
  fontWeight: 400,
  lineHeight: '52.99px',
  textAlign: 'center',
  width: '100%',
  height: 'auto',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.info.dark}`,
  display: 'grid',
  marginTop: '10px',
  wordBreak: 'break-word',
  textTransform: 'capitalize',
  color: theme.palette.info.main,
  background: theme.palette.primary.dark
}))

const BlurDiv = styled('div')(({ theme }) => ({
  width: '100%',
  height: '84px',
  position: 'relative',
  opacity: 0.5,
  borderRadius: '0px 0px 18px 18px',
  background: theme.palette.action.focus,
  bottom: '80px',
}))

const EditProfileButton = styled(Button)(({ theme }) => ({
  maxWidth: '125px',
  maxHeight: '44px',
  padding: '10px 16px',
  borderRadius: '30px',
  border: `1px solid ${theme.palette.info.contrastText}`,
  fontFamily: 'Rubik',
  fonSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  color: theme.palette.info.contrastText,
  textTransform: 'capitalize'
}))

const EmailSection = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: '10px 0px 10px 16px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.info.dark}`,
  height: '60px',
  margin: '5px',
  minWidth: '308px',
  color: theme.palette.info.main,
  justifyContent: 'space-between',
  background: theme.palette.primary.dark
}))
// Customizable Area End
