export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const lockIcon = require("../assets/lock.svg");
export const mobileIcon = require("../assets/password-smartphone.svg")
export const verifiedIcon = require("../assets/verificationModal.svg")
export const mobileIcon1 = require("../assets/mobileIcon.svg");
export const darkIcon = require("../assets/darkIcon.svg");
export const systemIcon = require("../assets/systemIcon.svg");
export const lightIcon = require("../assets/lightIcon.svg");
export const theme = require("../assets/theme.svg");
export const backArrow = require("../assets/backArrow.svg");
export const fontSide = require("../assets/fontSide.svg");
export const system_dark = require("../assets/system_dark.svg");
export const light_dark = require("../assets/light_dark.svg");
export const dark_dark = require("../assets/dark_dark.svg");
export const verification_dark = require("../assets/verification_dark.svg");
export const backArrow_dark = require("../assets/backArrow_dark.svg");
export const fullLogo = require("../assets/image_(2).svg");
export const smallLogo = require("../assets/image_(3).svg");