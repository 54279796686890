import React from "react";
// Customizable Area Start
import {
  ScrollView,
  SafeAreaView,
  StyleSheet,
} from "react-native";
import PostCreationController, { Props } from "./PostCreationController.web";
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import InsertLinkOutlinedIcon from '@material-ui/icons/InsertLinkOutlined';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { All, allBlack, group, groupBlack, lightVerify, darkVerify } from "../src/assets";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { ThemeProvider } from "@material-ui/core/styles";
import { BackButton, NextButton, darkTheme, lightTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import { Box, Button, Grid, Typography, styled, InputAdornment, Input, Menu, MenuItem, FormControlLabel } from "@material-ui/core";
import { Radio } from "antd";
import { EditHeading, FieldModal, ModalHeading, ModalLine, ModalSubHead } from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import { ToastContainer } from "react-toastify";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  publishModal = () => (
    <FieldModal
      open={this.state.publishModal}
      onCancel={this.closeModals}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h5">Publish</ModalHeading>
          <ModalLine style={{ width: '150px' }} />
          <ModalSubHead variant="subtitle2">Publish info</ModalSubHead>
          <ModalLine style={{ width: '150px' }} />
        </div>}
      centered
      closable={true}
      footer={null}
      destroyOnClose
      width={769}
      style={{ paddingBottom: '40px' }}
    >
      <Radio.Group
        data-test-id='changePublishType'
        value={this.state.publishType}
        onChange={this.changePublishType}
        style={{ width: "100%" }}
      >
        <div style={{ display: 'flex' }}>
          <ArrowBackIosIcon style={{ marginRight: "200px", marginLeft: '20px' }} onClick={this.closeModals} />
          <ModalHeading variant="h5">where to publish</ModalHeading>
        </div>
        <FormControlLabel
          value='all_companies'
          control={<Radio />}
          label={<RadioDiv>
            <img src={this.state.darkTheme ? allBlack : All} />
            <div>
              <RadioOptionHead>All</RadioOptionHead>
              <RadioOptionSubHead>The reminder will be sent to all companies “worker”.</RadioOptionSubHead>
            </div>
          </RadioDiv>}
          labelPlacement="start"
        />
        <FormControlLabel
          value='all_groups'
          control={<Radio />}
          label={<RadioDiv>
            <img src={this.state.darkTheme ? groupBlack : group} />
            <div>
              <RadioOptionHead>All Groups</RadioOptionHead>
              <RadioOptionSubHead>The Notification will be sent to all groups of all worker.</RadioOptionSubHead>
            </div>
          </RadioDiv>}
          labelPlacement="start"
        />
        <FormControlLabel
          value='specific_groups'
          control={<Radio />}
          label={<RadioDiv>
            <img src={this.state.darkTheme ? groupBlack : group} />
            <div>
              <RadioOptionHead>Specific Groups</RadioOptionHead>
              <RadioOptionSubHead>The Notification will be sent to specific groups.</RadioOptionSubHead>
              <RadioOptionSubHead>Ibrahim, Ibrahim1 and 3 others</RadioOptionSubHead>
            </div>
          </RadioDiv>}
          labelPlacement="start"
        />
      </Radio.Group>
      <BackButton data-test-id="closeModalButton" onClick={this.closeModals}>Back</BackButton>
      <NextButton
        data-test-id="addCourseButton"
        style={{ width: '65%', marginLeft: '10px', color: '#FFF' }}
        onClick={this.addNewCourse}
        disabled={!this.state.publishType}
      >
        Send
      </NextButton>
    </FieldModal>
  )

  successModal = () => (
    <FieldModal
      data-test-id="successModal"
      open={this.state.successModal}
      onCancel={this.closeSuccessModal}
      centered
      closable={true}
      footer={null}
      destroyOnClose
      width={696}
      style={{ height: '430px' }}
    >
      <div style={{ display: 'flex', justifyContent: "center", padding: '20px', flexDirection: 'column', alignItems: 'center' }}>
        <img src={this.state.darkTheme ? darkVerify : lightVerify} alt="verificationModal" style={{ height: '176px' }} />
        <EditHeading style={{ textAlign: 'center' }}>Your Course has been schedule Successfully</EditHeading>
      </div>
      <NextButton
        data-test-id="confirmModalButton"
        onClick={this.closeSuccessModal}
        fullWidth
        style={{ color: '#F8FAFC' }}
      >
        Done
      </NextButton>
    </FieldModal>
  )

  checkMedia = () => {
    const open = Boolean(this.state.anchorEl);
    if (this.state.imagePreview && this.state.videoPreview && this.state.uploadDoc) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <div style={{ display: "flex", maxHeight: "106px" }}>
          <img style={{ width: '50%', height: '100%', borderRadius: '20px' }} src={this.state.imagePreview} />
          <video style={{ width: '50%', height: '100%', borderRadius: '20px' }} src={this.state.videoPreview} />
        </div>
        <FileViewDiv >
          <MenuLines />
          <div>
            <FileNameTxt>{this.state.uploadDoc.name}</FileNameTxt>
            <FileSizeTxt>{(this.state.uploadDoc.size / 1024).toFixed(0)}{' KB'}</FileSizeTxt>
          </div>
          <CloseIcon data-test-id="removeFile" style={{ width: '16px' }} onClick={() => this.setState({ uploadDoc: null })} />
        </FileViewDiv>
      </div>
    } else if (this.state.imagePreview && this.state.videoPreview) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <div style={{ display: "flex", maxHeight: "106px" }}>
          <img style={{ width: '50%', height: '100%', borderRadius: '20px' }} src={this.state.imagePreview} />
          <video style={{ width: '50%', height: '100%', borderRadius: '20px' }} src={this.state.videoPreview} />
        </div>
        <div
          data-test-id="uploadMenu"
          aria-controls={open ? 'basic-menu' : undefined}
          id="basic-button"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
        >
          <AddCircleOutlineRoundedIcon style={{ marginRight: '10px', float: 'right' }} />
        </div>
      </div>
    } else if (this.state.imagePreview) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <img style={{ width: '90%', height: '70%', borderRadius: '20px' }} src={this.state.imagePreview} />
        <div
          data-test-id="uploadMenu"
          aria-controls={open ? 'basic-menu' : undefined}
          id="basic-button"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
        >
          <AddCircleOutlineRoundedIcon style={{ marginRight: '10px', float: 'right' }} />
        </div>
      </div>
    } else if (this.state.videoPreview) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <video style={{ width: '90%', height: '70%', borderRadius: '20px' }} src={this.state.videoPreview} />
        <div
          data-test-id="uploadMenu"
          aria-controls={open ? 'basic-menu' : undefined}
          id="basic-button"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
        >
          <AddCircleOutlineRoundedIcon style={{ marginRight: '10px', float: 'right' }} />
        </div>
      </div>
    } else if (this.state.uploadDoc) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <FileViewDiv >
          <MenuLines />
          <div>
            <FileNameTxt>{this.state.uploadDoc.name}</FileNameTxt>
            <FileSizeTxt>{(this.state.uploadDoc.size / 1024).toFixed(0)} {" KB"}</FileSizeTxt>
          </div>
          <CloseIcon style={{ width: '16px' }} onClick={() => this.setState({ uploadDoc: null })} />
        </FileViewDiv>
        <div
          data-test-id="uploadMenu"
          aria-controls={open ? 'basic-menu' : undefined}
          id="basic-button"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
        >
          <AddCircleOutlineRoundedIcon style={{ marginRight: '10px', float: 'right' }} />
        </div>
      </div >
    } else {
      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'end' }}
        data-test-id="uploadMenu"
        aria-controls={open ? 'basic-menu' : undefined}
        id="basic-button"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
      >
        <CloudUploadOutlinedIcon style={{ marginTop: '100px' }} />
        <UploadText>Upload media</UploadText>
      </div>
    }
  }

  renderSuggestionBox = () => {
    const { suggestions, showSuggestions, suggestionBoxPosition } = this.state;

    if (!showSuggestions) return null;

    return (
      <div
        style={{
          position: 'absolute',
          top: suggestionBoxPosition.top,
          left: suggestionBoxPosition.left,
          backgroundColor: 'white',
          border: '1px solid #ccc',
          borderRadius: '4px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
          zIndex: 1000
        }}
      >
        <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
          {suggestions.map((sugg: any, index: any) => (
            <>
              {sugg?.suggestions.map((suggestion: any, i: any) => (
                <li
                  key={index}
                  style={{
                    padding: '8px 12px',
                    cursor: 'pointer',
                    borderBottom: '1px solid #eee'
                  }}
                  onMouseDown={() => this.handleSuggestionClick(suggestion)}
                >
                  {suggestion}</li>
              ))}
            </>
          ))}
        </ul>
      </div>
    );
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const open = Boolean(this.state.anchorEl);
    // Customizable Area End
    return (
      // Customizable Area Start
      <SafeAreaView style={styles.safeAreaView}>
        <ScrollView>
          {/* Customizable Area Start */}
          <ThemeProvider theme={this.state.darkTheme ? darkTheme : lightTheme}>
            <Loader loading={this.state.loading} />
            <HamburgerMenu navigation={this.props.navigation} handleMenu={this.leftMenuHandle} open={this.state.openLeftMenu} handleTheme={this.handleTheme} themeDark={this.state.darkTheme} />
            <MainContainer container spacing={4} style={{ paddingLeft: this.state.openLeftMenu ? 273 : 150 }}>
              <LeftSection item xs={7}>
                <CourseInfoSection>
                  <CourseInfoTitle>Course Info</CourseInfoTitle>
                  <hr style={{ width: '55%' }} />
                </CourseInfoSection>
                <CourseDetailsSection>
                  <CourseTitleDescription>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the prin
                  </CourseTitleDescription>
                  <SubHeading>Course title</SubHeading>
                  <InputField
                    data-test-id="txtCourseTitle"
                    placeholder="Enter course title"
                    value={this.state.CourseInfo['course_name']}
                    onChange={(event) => this.changeCourseInfo('course_name', event.target.value)}
                  />
                  <SubHeading>Class Description</SubHeading>
                  <div onDoubleClick={this.handleOpen} onContextMenu ={this.handleSelectionChange}>
                    {this.state.open &&(
                       <div style={{position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, background: '#00000050', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 99999999}}
                      >
                        <div
                         style={{width: 715, height: 404, background: 'white',borderRadius:"8px"}}
                        >
                          <ReactQuill

                            ref={this.quillRef}
                            data-test-id="txtDescription2"
                            placeholder={"Enter your Description"}
                            value={this.state.CourseInfo['course_description'] && String(this.state.CourseInfo['course_description'])}
                            onChange={(event) => this.changeCourseInfo('course_description', event)}
                            onChangeSelection={this.handleSelectionChange}
                            formats={formats}
                         style={{height: 347}}
                            className="modalTextEditor"
                          />
                          {this.renderSuggestionBox()}
                       <div style={{display: 'flex', gap: 10, justifyContent: 'flex-end', paddingBlock: 8, marginInline: 18, borderTop: '1px solid lightgray'}}>
                       <button  onClick={this.handleClose} style={{backgroundColor:"white", border: '1px solid #1A469C', borderRadius: 30, padding: '10px 16px', color: '#1A469C',fontWeight:700, fontSize: 14, fontFamily: "Rubik"}}>Cancel</button>
                       <button  onClick={this.handleClose} style={{backgroundColor:"#1A469C",borderRadius: 30, padding: '10px 24px', border: 'none', color: 'white',fontWeight:700, fontSize: 14, fontFamily: "Rubik"}}>Save</button>
                          </div>
                        </div>
                      </div>
                    )}

                    <ReactQuill
                      ref={this.quillRef}
                      data-test-id="txtDescription"
                      placeholder={"Enter your Description"}
                      value={this.state.CourseInfo['course_description'] && String(this.state.CourseInfo['course_description'])}
                      onChange={(event) => this.changeCourseInfo('course_description', event)}
                      onChangeSelection={this.handleSelectionChange}
                      formats={formats}
                    />
                    {this.renderSuggestionBox()}
                  </div>

                  <CategoryDiv>
                    <div style={{ width: '50%', marginRight: '10px' }}>
                      <SubHeading>Category</SubHeading>
                      <InputField
                        data-test-id="txtCategory"
                        placeholder="Enter your category"
                        fullWidth
                        value={this.state.CourseInfo['category']}
                        onChange={(event) => this.changeCourseInfo('category_attributes', event.target.value)}
                      />
                    </div>
                    <div style={{ width: '50%' }}>
                      <SubHeading>Sub-category</SubHeading>
                      <InputField
                        data-test-id="txtSubCategory"
                        placeholder="Enter your sub-category"
                        fullWidth
                        value={this.state.CourseInfo['sub_category']}
                        onChange={(event) => this.changeCourseInfo('sub_category', event.target.value)}
                      />
                    </div>
                  </CategoryDiv>
                  <SubHeading>Tags</SubHeading>
                  <InputField
                    data-test-id="txtTags"
                    placeholder="#group"
                    fullWidth
                    value={this.state.CourseInfo['tags']}
                    onChange={(event) => this.changeCourseInfo('tags', event.target.value)}
                  />
                </CourseDetailsSection>
              </LeftSection>

              <RightSection item xs={4}>
                <CourseContentTxt>Course cover</CourseContentTxt>
                {this.state.coverPhotoPreview ?
                  <UploadMediaDiv style={{ marginBottom: "10px" }}>
                    <img src={this.state.coverPhotoPreview} style={{ width: "100%", height: "100%", borderRadius: "15px" }} />
                  </UploadMediaDiv>
                  : <label htmlFor="myCover" className="profile-upload-btn" data-test-id="profile-upload-btn">
                    <input
                      data-test-id="updateCover"
                      id="myCover"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={this.uploadCover}
                    /><InputField
                      value="upload cover"
                      fullWidth
                      disabled
                      endAdornment={
                        <AddCircleOutlineRoundedIcon />
                      }
                    />
                  </label>}
                {this.state.addNewModule && <ModuleDiv>
                  <CourseContentTxt>Course Media</CourseContentTxt>
                  <UploadMediaDiv>
                    {this.checkMedia()}
                  </UploadMediaDiv>
                  <UploadMenu
                    id="basic-menu"
                    open={open}
                    anchorEl={this.state.anchorEl}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    onClose={() => this.setState({ anchorEl: null })}
                    style={{ top: '10px', left: '10px' }}
                  >
                    <MenuItem>
                      <MenuText>
                        Select
                      </MenuText>
                    </MenuItem>
                    <MenuItem>
                      <CloudUploadOutlinedIcon />
                      <label htmlFor="myInput" className="profile-upload-btn" data-test-id="profile-upload-btn">
                        <input
                          data-test-id="updateImage"
                          id="myInput"
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={this.uploadMedia}
                        />
                        <MenuText>
                          Image
                        </MenuText>
                      </label>
                    </MenuItem>
                    <MenuItem data-test-id="uploadVideo">
                      <InsertLinkOutlinedIcon />
                      <label htmlFor="myVideo" className="profile-upload-btn" data-test-id="profile-upload-btn">
                        <input
                          data-test-id="updateVideo"
                          id="myVideo"
                          type="file"
                          accept="video/*"
                          style={{ display: "none" }}
                          onChange={this.uploadMedia}
                        />
                        <MenuText>
                          Video
                        </MenuText>
                      </label>
                    </MenuItem>
                    <MenuItem>
                      <InsertLinkOutlinedIcon />
                      <label htmlFor="myDoc" className="profile-upload-btn" data-test-id="profile-upload-btn">
                        <input
                          data-test-id="uploadDoc"
                          id="myDoc"
                          type="file"
                          accept=".pdf,.doc,.docx,.txt,.xls,.xlsx"
                          style={{ display: "none" }}
                          onChange={this.uploadMedia}
                        />
                        <MenuText>
                          Documents
                        </MenuText>
                      </label>
                    </MenuItem>
                  </UploadMenu>
                  <CourseContentTxt>Course Content</CourseContentTxt>
                  <TitleLessonDiv>
                    <CategoryDiv>
                      <div style={{ width: '50%', marginRight: '10px' }}>
                        <SubHeading>Title</SubHeading>
                        <InputField
                          data-test-id="txtTitle"
                          placeholder="Enter title"
                          fullWidth
                          value={this.state.moduleContent['title']}
                          onChange={(event) => this.changeLessonInfo('title', event.target.value)}
                        />
                      </div>
                      <div style={{ width: '50%' }}>
                        <SubHeading>lesson name</SubHeading>
                        <InputField
                          data-test-id="txtLesson"
                          placeholder="Enter lesson"
                          fullWidth
                          value={this.state.moduleContent['lesson_name']}
                          onChange={(event) => this.changeLessonInfo('lesson_name', event.target.value)}
                        />
                      </div>
                    </CategoryDiv>
                    <InputField
                      data-test-id="directorySelect"
                      placeholder="Select Directory"
                      fullWidth
                      value={this.state.moduleContent['lesson_number']}
                      onChange={(event) => this.changeLessonInfo('lesson_number', event.target.value)}
                    />

                  </TitleLessonDiv>
                  <AddContentButton
                    data-test-id='addLessonDetails'
                    onClick={this.addLessonDetails}
                    className="publishBg"
                  >
                    Add
                  </AddContentButton>
                </ModuleDiv>}
                {this.state.allModules?.map((item) => {
                  return <LessonDiv>
                    <div style={{ display: 'flex' }}>
                      <PlayCircleOutlineOutlinedIcon />
                      <LessonName>{item.lesson_number}</LessonName>
                    </div>
                    <LessonTime>
                      5:17
                    </LessonTime>
                  </LessonDiv>
                })}
                <AddModuleButton
                  data-test-id='addNewModule'
                  onClick={() => this.setState({ addNewModule: true })}
                  endIcon={<AddCircleOutlineRoundedIcon />}
                >
                  Add Module
                </AddModuleButton>
                <PublishButton
                  className="publishBg"
                  data-test-id="publishPost"
                  onClick={this.openPublishModal}
                >
                  Publish
                </PublishButton>
                {this.publishModal()}
                {this.successModal()}
              </RightSection>
              <ToastContainer containerId='A' />
            </MainContainer>
          </ThemeProvider >
          {/* Customizable Area End */}
        </ScrollView>
      </SafeAreaView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    alignSelf: "center",
    width: "100%"
  }
})
const MainContainer = styled(Grid)(({ theme }) => ({
  paddingTop: '10px',
  height: '100%',
  background: theme.palette.background.default,
  paddingRight: 48,
  paddingBottom: 50,
  transition: 'all 100ms linear',
  '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
    borderBottom: 'transparent'
  },
  '& .MuiInput-underline::after': {
    borderBottom: 'transparent'
  },
  "& .MuiInput-underline::before": {
    borderBottom: 'transparent'
  },
  '& .MuiSvgIcon-root': {
    fill: '#94A3B8'
  },
  "& .ant-select": {
    width: "auto",
    height: '38px',
    fontSize: '12px',
    fontFamily: 'Rubik',
    lineHeight: '18px',
    fontWeight: 400,
    color: theme.palette.secondary.main
  },
  "& .ant-select:not(.ant-select-customize-input) .ant-select-selector": {
    minWidth: '91px',
    borderRadius: "8px",
    padding: '4px 12px',
    height: '38px',
    borderColor: theme.palette.info.dark,
    background: theme.palette.action.focus,
  },
  "& .ant-select-dropdown": {
    fontFamily: 'Rubik',
    width: 'auto !important',
    fontSize: '12px',
    fontWeight: 400,
    background: theme.palette.action.focus,
    minWidth: "200px",
  },
  "& .ant-select-arrow": {
    color: theme.palette.info.main
  },
  "& .ql-container": {
    borderRadius: '8px',
    height: "174px",
    border: 'none'
  },
  '& .quill': {
    position: 'relative',
    borderRadius: '8px',
    paddingBottom: 55,
    border: `1px solid ${theme.palette.info.dark}`,
    borderTop: `1px solid ${theme.palette.info.dark} !important`
  },
  "& .ql-toolbar": {
    borderRadius: '8px',
    border: `none`,
    position: "absolute",
    bottom: "10px",
    left: 10,
    width: "fit-content",
    transform: "translateY",
    zIndex: 1000,
    '& .ql-formats': {
      marginRight: 0
    },
    '& .ql-formats:first-child': {
      display: 'none'
    },
    '& .ql-formats button': {
      border: '1px solid #94A3B8',
      marginRight: 2
    },
  },
  "& .ql-editor": {
    background: theme.palette.background.default,
    color: theme.palette.secondary.main,
    borderRadius: 8,
    fontSize: '14px',
    fontFamily: 'Rubik'
  },
  "& .ql-editor.ql-blank::before": {
    color: theme.palette.info.light,
    fontStyle: 'normal',
    fontFamily: 'Rubik',
    fontSize: '14px'
  }
}))

const LeftSection = styled(Grid)(({ theme }) => ({
  display: 'flex',
  borderRadius: '18px',
  background: theme.palette.action.focus,
  marginRight: '10px',
  flexDirection: 'column'
}))

const RightSection = styled(Grid)(({ theme }) => ({
  display: 'flex',
  borderRadius: '18px',
  background: theme.palette.action.focus,
  flexDirection: 'column',
  "& .publishBg:hover": {
    background: theme.palette.info.contrastText
  }
}))

const CourseInfoSection = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  height: '38px'
})

const CourseTitleDescription = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '21px',
  textAlign: 'left',
  color: theme.palette.info.main
}))

const CourseInfoTitle = styled(Typography)(({ theme }) => ({
  fontSize: '32px',
  lineHeight: '37',
  fontWeight: 500,
  color: theme.palette.secondary.main
}))

const SubHeading = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "22px",
  fontWeight: 700,
  color: theme.palette.text.primary,
  marginTop: '5px'
}))

const UploadMediaDiv = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '190px',
  borderRadius: '22px',
  border: `1px solid ${theme.palette.primary.main}`,
  boxShadow: '0px 8px 32px 0px #0000000F',
  flexDirection: 'column'
}))

const TitleLessonDiv = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '193px',
  padding: '36px 28px',
  borderRadius: 'px',
  border: `1px solid ${theme.palette.primary.main}`,
  boxShadow: '0px 8px 32px 0px #0000000F',
  marginTop: '10px'
}))

const AddModuleButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '45px',
  borderRadius: '30px',
  border: `1px solid ${theme.palette.info.contrastText}`,
  marginTop: '32px',
  textTransform: 'capitalize',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '22px',
  color: theme.palette.info.contrastText,
  '& .MuiSvgIcon-root': {
    fill: theme.palette.info.contrastText
  }
}))

const PublishButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '44px',
  borderRadius: '30px',
  marginTop: '10px',
  textTransform: 'capitalize',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  background: theme.palette.info.contrastText,
  color: '#FFF'
}))

const InputField = styled(Input)(({ theme }) => ({
  width: '100%',
  height: '42px',
  gap: '8px',
  borderRadius: '8px',
  padding: '10px 12px',
  border: `1px solid ${theme.palette.info.dark}`,
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  textAlign: 'left',
  color: theme.palette.secondary.main,
  marginTop: '5px',
  background: theme.palette.background.default,
}))

const CategoryDiv = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
})

const CourseDetailsSection = styled(Box)({
  maxHeight: '700px',
  overflowY: 'scroll',
  marginTop: '30px',
  '& .modalTextEditor .ql-container.ql-snow': {
    height: '100%',
    border: 'none'
  }
})

const UploadText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '26px',
  color: theme.palette.text.secondary
}))

const CourseContentTxt = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: "26px",
  marginTop: '5px',
  color: theme.palette.info.main
}))

const MenuText = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.light,
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  marginLeft: '10px'
}))

export const RadioDiv = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row'
}))

export const RadioOptionHead = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.41px',
  color: theme.palette.info.main,
  marginLeft: '10px'
}))

export const RadioOptionSubHead = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  color: theme.palette.info.light,
  marginLeft: '10px'
}))

const ModuleDiv = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  borderRadius: '18px',
  padding: '12px',
  display: 'flex',
  flexDirection: 'column'
}))

const AddContentButton = styled(Button)(({ theme }) => ({
  width: '145px',
  height: '44px',
  borderRadius: '30px',
  padding: '10px 16px',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  color: '#FFF',
  marginTop: '20px',
  background: theme.palette.action.selected,
  textTransform: 'capitalize',
  alignSelf: 'center'
}))

const LessonDiv = styled(Box)(({ theme }) => ({
  maxWidth: '304px',
  height: '42px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 11px'
}))

const LessonName = styled('span')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '22px',
  marginLeft: '10px'
}))

const LessonTime = styled('span')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '26px',
}))

const FileNameTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
}))

const FileSizeTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.light,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
}))

const FileViewDiv = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: 'row',
  padding: '8px',
  justifyContent: 'space-around',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '8px',
  alignItems: 'center'
}))

const UploadMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.background.default,
    boxShadow: `rgba(0, 0, 0, 0.15) 0px 2px 4px 0px`,
    borderRadius: '18px',
    border: `1px solid ${theme.palette.action.focus}`,
    width: '200px',
  },
  '& .MuiListItem-button:hover': {
    backgroundColor: theme.palette.warning.main
  }
}))

const MenuLines = styled(MenuIcon)(({ theme }) => ({
  height: '50px',
  width: '50px',
  background: theme.palette.background.paper,
  padding: '10px'
}))

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color"
];
// Customizable Area End
