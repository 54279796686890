import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  IGroup,
  IAccount,
  IGroupAccount,
  ITask,
  Status,
  Priority,
  ISubTask,
  ITaskProgress
} from "./types";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  title: string;
  description: string;
  priority: string;
  status: string;
  status1: string;
  assign_to: string;
  assign_to_type: string;
  editMode: boolean;
  token: string;
  taskList: ITask[];
  taskList1: ITask[];
  isVisibleModal: boolean;
  isVisibleAssignModal: boolean;
  dropdownGroup: boolean;
  dropdownStatus: boolean;
  dropdownAccount: boolean;
  dropdownPriority: boolean;
  groupList: IGroup[];
  statusList: Status[];
  priorityList: Priority[];
  selectedAccounts: IGroupAccount[];
  accountsData: IAccount[];
  selectedTheme: boolean;
  openMenu: boolean;
  inProgressStatus: boolean;
  completedStatus: boolean;
  importantStatus: boolean;
  inReviewStatus: boolean;
  onHoldStatus: boolean;
  cancelledStatus: boolean;
  subTask: ISubTask[];
  enableSubTask: boolean;
  taskDueDate: string;
  anchorOpen: null | HTMLElement;
  selectedId: number;
  selectedItem: ITask;
  anchorSubOpen: null | HTMLElement;
  expirationDate: string;
  expanded: boolean;
  expandTaskProgress: boolean;
  taskProgressStatus: ITaskProgress;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTasksApiCallId = "";
  getTasksApiCallId1 = "";
  getTaskProgressCallId = "";
  postTaskApiCallId = "";
  putTaskApiCallId = "";
  deleteTaskApiCallId = "";
  getGroupsApiCallId = "";
  assignGroupApiCallId = "";
  getAccountsApiCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "",
      title: "",
      description: "",
      priority: "",
      status: "in_progress",
      status1: "in_review",
      assign_to: "",
      assign_to_type: "",
      editMode: false,
      token: "",
      taskList: [],
      taskList1: [],
      isVisibleModal: false,
      isVisibleAssignModal: false,
      dropdownGroup: false,
      dropdownStatus: false,
      dropdownAccount: false,
      dropdownPriority: false,
      groupList: [],
      selectedAccounts: [],
      accountsData: [],
      statusList: [
        { id: 1, name: "to_do" },
        { id: 2, name: "in_progress" },
        { id: 3, name: "complete" },
      ],
      priorityList: [
        { id: 1, name: "low" },
        { id: 2, name: "medium" },
        { id: 3, name: "high" },
      ],
      selectedTheme: false,
      openMenu: false,
      inProgressStatus: true,
      inReviewStatus: true,
      completedStatus: false,
      importantStatus: false,
      onHoldStatus: false,
      cancelledStatus: false,
      subTask: [{ name: "", is_completed: false }],
      enableSubTask: false,
      taskDueDate: "",
      anchorOpen: null,
      selectedId: 0,
      anchorSubOpen: null,
      expirationDate: "",
      expanded: false,
      selectedItem: {
        id: "",
        isSelected: false,
        attributes: {
          id: 1,
          title: "",
          account_id: 1,
          status: "in_progress",
          description: "",
          created_at: new Date,
          updated_at: new Date,
          priority: "high",
          assigned_to: {
            id: '',
            isSelected: false,
            type: "profile",
            attributes: {
              "id": 83,
              "last_name": "",
              "first_name": "",
              "date_of_birth": "",
              "email": "",
              "role_id": "",
              "preferred_name": "",
              "employee_number": 1,
              "full_phone_number": "",
              "company_reference": "",
              "location": "",
              "account_id": 1,
              "completed_course": "",
              "group_id": "",
              "image": "",
              "company_id": 1,
              "group_name": "",
              "phone_number_verified": false,
              "company_name": "",
              "selected_theme": "",
              "font_size": ""
            },
          },
          due_date: "",
          expiration_date: "",
          task_lists: [],
          completion_percentage: 0
        }
      },
      expandTaskProgress: false,
      taskProgressStatus: {
        "percentage_completed": 0,
        "percentage_in_progress": 0,
        "percentage_important": 0,
        "percentage_in_review": 0,
        "percentage_on_hold": 0,
        "percentage_cancelled": 0
      }
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let darkMode = await getStorageData('darkMode');
    if (darkMode === 'true') {
      this.setState({ selectedTheme: true })
    }
    const token = await getStorageData('authToken');
    this.setState({ token: token });
    this.getTasks(token);
    this.getTasksProgress();
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (responseJson) {
        this.checkTaskResponses(message, apiRequestCallId)
      }
      // Condition to fetch group list.
      else if (apiRequestCallId === this.getGroupsApiCallId) {
        this.setState({
          groupList: responseJson.data.sort(
            (a: IGroup, b: IGroup) => parseInt(a.id) - parseInt(b.id)
          ),
        });
      }

      // Condition to assign task to the groups/accounts.
      else if (apiRequestCallId === this.assignGroupApiCallId) {
        this.getTasks(this.state.token);
        this.setState({ isVisibleAssignModal: false });
      }
    }
  }

  checkTaskResponses = (message: Message, apiRequestCallId: string) => {
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    // Condition for fetching tasks list.
    if (apiRequestCallId === this.getTasksApiCallId) {
      this.getTasks1(this.state.token)
      if (responseJson.data) {
        this.setState({
          taskList: responseJson.data.sort(
            (a: ITask, b: ITask) => parseInt(a.id) - parseInt(b.id)
          ),
        });
      } else {
        this.setState({ taskList: [] });
      }
    }
    // Condition for fetching tasks list1.
    else if (apiRequestCallId === this.getTasksApiCallId1) {
      if (responseJson.data) {
        this.setState({
          taskList1: responseJson.data
        });
      } else {
        this.setState({ taskList1: [] });
      }
    }
    // Condition to create new task.
    else if (apiRequestCallId === this.postTaskApiCallId) {
      this.getTasks(this.state.token);
      this.setState({ isVisibleModal: false });
      toast.success("Task added successfully")
    }
    // Condition to edit task details.
    else if (apiRequestCallId === this.putTaskApiCallId) {
      toast.success("Task Updated Successfully", { containerId: 'B' })
      this.getTasks(this.state.token);
      this.setState({ isVisibleModal: false });
    }

    // Condition to delete task.
    else if (apiRequestCallId === this.deleteTaskApiCallId) {
      toast.success(responseJson.message)
      this.getTasks(this.state.token);
    }

    else if (apiRequestCallId === this.getTaskProgressCallId) {
      this.setState({ taskProgressStatus: responseJson })
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  hideAssignModal = () => {
    this.setState({ isVisibleAssignModal: false });
  };

  handleInputTitle = (title: string) => {
    this.setState({ title });
  };

  handleInputDescription = (description: string) => {
    this.setState({ description });
  };

  toggleAssignModal = () => {
    this.getAccounts(this.state.token);
    this.getGroups(this.state.token);
    this.setState({
      dropdownAccount: false,
      dropdownGroup: false,
      isVisibleAssignModal: !this.state.isVisibleAssignModal,
      editMode: true,
    });
  };

  showAddModal = () => {
    if (this.state.enableSubTask) {
      this.setState({ enableSubTask: false })
    } else {
      this.setState({
        title: "",
        description: "",
        isVisibleModal: !this.state.isVisibleModal,
        editMode: false,
        enableSubTask: false,
        taskDueDate: '',
        subTask: [{ name: "", is_completed: false }]
      });
    }
  };

  goToSubTask = () => {
    this.setState({ enableSubTask: true })
  }

  checkImportant = () => {
    this.setState({ status: "important" })
  }

  addSubTask = () => {
    let values = this.state.subTask;
    values.push({ name: "", is_completed: false });
    this.setState({ subTask: values });
  }

  removeField = (index: number) => {
    let value = this.state.subTask;
    value.splice(index, 1);
    this.setState({ subTask: value })
  }

  changeSubTask = (value: string, indexId: number) => {
    let newValue = this.state.subTask;
    this.state.subTask.forEach((element, index) => {
      if (index === indexId) {
        newValue[index].name = value
      }
      this.setState({ subTask: newValue })
    })
  }

  handleOptionMenu = (event: React.MouseEvent<HTMLElement>, item: ITask) => {
    this.setState({ anchorOpen: event.currentTarget, selectedId: item.attributes.id, selectedItem: item });
  };

  handleSubOptionMenu = (event: React.MouseEvent<HTMLElement>, key: number) => {
    this.setState({ anchorSubOpen: event.currentTarget, selectedId: key });
  };

  changeStatus = (item: ITask, status: string) => {
    this.setState({
      status: status,
      anchorOpen: null,
      anchorSubOpen: null,
      title: item.attributes.title,
      description: item.attributes.description,
      subTask: item.attributes.task_lists ? item.attributes.task_lists : [{ 'name': "", is_completed: false }],
      taskDueDate: item.attributes.due_date ? item.attributes.due_date : ""
    }, () => this.editTask(String(item.attributes.id))
    )
  }

  changeInprogress = (value1: boolean, value2: boolean, value3: boolean, status: string) => {
    this.setState({
      status: status,
      inProgressStatus: value1,
      completedStatus: value2,
      importantStatus: value3
    }, () => this.getTasks(this.state.token))
  }

  changeTaskProgress = (event: CheckboxChangeEvent, item: ISubTask) => {
    const newObj = new FormData();
    newObj.append(`[task_list][is_completed]`, JSON.stringify(event.target.checked))
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.putTaskListApiEndPoint + "/" + `${item.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      newObj
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  changeReviewStatus = (value1: boolean, value2: boolean, value3: boolean, status: string) => {
    this.setState({
      status1: status,
      inReviewStatus: value1,
      onHoldStatus: value2,
      cancelledStatus: value3
    }, () => this.getTasks1(this.state.token))
  }

  showEditModal = () => {
    this.setState({
      selectedId: this.state.selectedItem.attributes.id,
      title: this.state.selectedItem.attributes.title,
      description: this.state.selectedItem.attributes.description,
      taskDueDate: this.state.selectedItem.attributes.due_date ? this.state.selectedItem.attributes.due_date : "",
      expirationDate: this.state.selectedItem.attributes.expiration_date ? this.state.selectedItem.attributes.expiration_date : "",
      status: this.state.selectedItem.attributes.status,
      anchorOpen: null,
      isVisibleModal: !this.state.isVisibleModal,
      editMode: true,
      subTask: this.state.selectedItem.attributes.task_lists ? this.state.selectedItem.attributes.task_lists : [{ name: "", is_completed: false }]
    });
  };

  expandGroupView = () => {
    this.setState({
      dropdownGroup: !this.state.dropdownGroup,
    });
  };

  expandStatusView = () => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus,
    });
  };

  expandPriorityView = () => {
    this.setState({
      dropdownPriority: !this.state.dropdownPriority,
    });
  };

  expandAccountsView = () => {
    this.setState({ dropdownAccount: !this.state.dropdownAccount });
  };

  handleAccountSelect = (dataId: string) => {
    let newData = this.state.accountsData.map((account: IAccount) => {
      if (account.id === dataId) {
        return { ...account, isSelected: !account.isSelected };
      }
      return account;
    });
    this.setState({ accountsData: newData });
  };

  handleSelectPriority = (item: Priority) => {
    this.setState({ priority: item.name, dropdownPriority: false });
  };

  handleSelectStatus = (item: Status) => {
    this.setState({ status: item.name, dropdownStatus: false });
  };

  handleAssignToModal = (item: ITask) => {
    this.setState({
      id: item.id,
      title: item.attributes.title,
      description: item.attributes.description,
      priority: item.attributes.priority,
      status: item.attributes.status,
    });
    if (item.attributes.assigned_to) {
      this.setState({
        assign_to:
          item.attributes.assigned_to.type === "group"
            ? item.attributes.assigned_to.attributes.name
            : item.attributes.assigned_to.attributes?.first_name || "",
        assign_to_type: item.attributes.assigned_to.type,
      });
    }
    this.toggleAssignModal();
  };

  handleEditTask = (item: ITask) => {
    this.setState({
      id: item.id,
      title: item.attributes.title,
      description: item.attributes.description,
      priority: item.attributes.priority,
      status: item.attributes.status,
    });
    this.showEditModal();
  };

  // Function to fetch tasks list from the API
  getTasks = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTasksApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}?status=${this.state.status}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTasks1 = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTasksApiCallId1 = requestMessage1.messageId;

    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}?status=${this.state.status1}`
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage1.id, requestMessage1);
  };

  getTasksProgress = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };
    const requestMessage2 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskProgressCallId = requestMessage2.messageId;

    requestMessage2.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.selfTaskProgressApiEndPoint
    );
    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage2.id, requestMessage2);
  };
  // Function to add new task and send it to API
  addTask = async () => {
    let token = await getStorageData('authToken')
    const found = this.state.subTask.some(element => element.name === '');
    if (found) {
      toast.error("All sub task are mandatory")
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: token,
      };
      const httpBody = {
        "task": {
          title: this.state.title,
          description: this.state.description,
          status: this.state.status,
          priority: this.state.priority,
          due_date: this.state.taskDueDate,
          task_lists_attributes: this.state.subTask
        }
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postTaskApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to update task details and send it to API
  editTask = (taskId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const httpBody = {
      "task": {
        title: this.state.title,
        description: this.state.description,
        status: this.state.status,
        priority: this.state.priority,
        due_date: this.state.taskDueDate,
        task_lists_attributes: this.state.subTask
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksApiEndPoint + "/" + `${taskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to delete task and send it to API
  deleteTask = (taskId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksApiEndPoint + "/" + `${taskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to fetch groups list from the API
  getGroups = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.groupsApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to assign task to groups/accounts and sent it to the API
  handleAssignTo = (
    taskId: string,
    assignToType: string,
    assignToId: string
  ) => {
    if (
      this.isStringNullOrBlank(taskId) ||
      this.isStringNullOrBlank(assignToId)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        assigned_to_type: assignToType,
        assigned_to_id: assignToId,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.assignGroupApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksApiEndPoint + "/" + `${taskId}` + "/assign"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to fetch accounts list from the API
  getAccounts = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksAccountsApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeTheme = () => {
    this.setState({ selectedTheme: !this.state.selectedTheme })
    setStorageData('darkMode', JSON.stringify(!this.state.selectedTheme))
  }

  toggleMenu = () => {
    this.setState({ openMenu: !this.state.openMenu })
    setStorageData('open', JSON.stringify(!this.state.openMenu))
  }
  // Customizable Area End
}
