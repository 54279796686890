import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ISubTask, ITask, ITaskList, ITaskProgress } from "./types";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: number;
  // Customizable Area Start
  name: string;
  editMode: boolean;
  token: string;
  taskLists: ITaskList[];
  taskLists2: ITaskList[];
  tasksData: ITask[];
  selectedTasks: ITask;
  subTasks: ISubTask[];
  isVisibleModal: boolean;
  dropdownTasks: boolean;
  sideMenu: boolean;
  blackTheme: boolean;
  enableAssignedTask: boolean;
  enableInProgressTask: boolean;
  enableImportantTask: boolean;
  enableCompletedTask: boolean;
  taskStatus: string;
  taskStatus1: string;
  editAnchor: null | HTMLElement;
  taskTitle: string;
  taskDescription: string;
  dueDuration: string;
  allocate_type: string;
  showSubTask: boolean;
  publishModal: boolean;
  selectedTaskId: number;
  expandProgress: boolean;
  taskProgress: ITaskProgress;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTaskListsApiCallId = "";
  postTaskListApiCallId = "";
  putTaskListApiCallId = "";
  putTaskProgressApiCallId = "";
  deleteTaskListApiCallId = "";
  getTasksApiCallId = "";
  getTaskListsApiCallId2 = "";
  getTaskProgressApiCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: 0,
      name: "",
      editMode: false,
      token: "",
      taskLists: [],
      taskLists2: [],
      tasksData: [],
      selectedTasks: {
        isSelected: false,
        id: "",
        attributes: {
          id: 1,
          account_id: 1,
          title: "",
          description: "",
          status: "in_progress",
          priority: "high",
          created_at: new Date,
          updated_at: new Date,
          completion_percentage: 0,
          assigned_to: {
            isSelected: false,
            id: '',
            type: "profile",
            attributes: {
              "id": 83,
              "first_name": "",
              "last_name": "",
              "email": "",
              "date_of_birth": "",
              "preferred_name": "",
              "role_id": "",
              "full_phone_number": "",
              "employee_number": 1,
              "location": "",
              "company_reference": "",
              "completed_course": "",
              "account_id": 1,
              "image": "",
              "group_id": "",
              "group_name": "",
              "company_id": 1,
              "company_name": "",
              "phone_number_verified": false,
              "selected_theme": "",
              "font_size": ""
            },
          },
          expiration_date: "",
          due_date: "",
          task_lists: [],
        }
      },
      subTasks: [{ name: "", is_completed: false }],
      isVisibleModal: false,
      dropdownTasks: false,
      sideMenu: false,
      blackTheme: false,
      enableAssignedTask: true,
      enableInProgressTask: true,
      enableImportantTask: false,
      enableCompletedTask: false,
      taskStatus: "assigned",
      taskStatus1: "in_progress",
      editAnchor: null,
      taskTitle: "",
      taskDescription: "",
      dueDuration: "",
      allocate_type: "all_company",
      showSubTask: false,
      publishModal: false,
      selectedTaskId: 0,
      expandProgress: false,
      taskProgress: {
        "percentage_completed": 0,
        "percentage_in_progress": 0,
        "percentage_important": 0,
        "percentage_in_review": 0,
        "percentage_on_hold": 0,
        "percentage_cancelled": 0
      }
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const theme = await getStorageData('darkMode');
    if (theme === 'true') {
      this.setState({ blackTheme: true })
    } else {
      this.setState({ blackTheme: false })
    }
    this.getToken()
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);

    let apiCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    this.parseApiCatchErrorResponse(errorResponse);

    this.checkTaskListResponses(message);

    if (apiCallId === this.getTaskProgressApiCallId) {
      this.setState({ taskProgress: responseJson })
    }
    // Condition for fetch tasks.
    if (apiCallId === this.getTasksApiCallId) {
      if (responseJson.data) {
        this.setState({
          // tasksData: data,
        });
      } else {
        this.setState({ tasksData: [] });
      }
    }

    if (apiCallId === this.putTaskProgressApiCallId) {
      if (responseJson.data) {
        this.getTaskLists(this.state.token)
      }
    }
  };

  checkTaskListResponses = (message: Message) => {
    let apiCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    // Condition for fetching task list.
    if (this.getTaskListsApiCallId === apiCallId) {
      if (responseJson.data) {
        this.setState({
          taskLists: responseJson.data
        }, () => this.getTaskLists2(this.state.token));
      } else {
        this.setState({ taskLists: [] }, () => this.getTaskLists2(this.state.token))
      }
    }

    if (this.getTaskListsApiCallId2 === apiCallId) {
      if (responseJson.data) {
        this.setState({ taskLists2: responseJson.data })
      }
      else {
        this.setState({ taskLists2: [] })
      }
    }

    // Condition for creating new task list.
    if (apiCallId === this.postTaskListApiCallId) {
      if (responseJson.data) {
        toast.success("Task added successfully");
        this.getTaskLists(this.state.token);
        this.setState({ isVisibleModal: false, showSubTask: false });
      }
    }

    // Condition for edit task list.
    if (apiCallId === this.putTaskListApiCallId) {
      if (responseJson.data) {
        toast.success("Task updated successfully");
        this.getTaskLists(this.state.token);
        this.setState({ isVisibleModal: false });
      }
    }

    // Condition for delete task list.
    if (apiCallId === this.deleteTaskListApiCallId) {
      if (responseJson.message) {
        toast.success(" Task deleted successfully");
        this.getTaskLists(this.state.token);
      }
    }
  }

  getToken = async () => {
    const token = await getStorageData('authToken')
    this.setState({ token: token });
    this.getTaskLists(token);
    this.getTaskProgress();
  };

  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  hideModal = () => {
    this.setState({ isVisibleModal: !this.state.isVisibleModal });
  };

  toggleSideMenu = () => {
    this.setState({ sideMenu: !this.state.sideMenu })
  }

  themeChanges = () => {
    this.setState({ blackTheme: !this.state.blackTheme })
    setStorageData('darkMode', `${!this.state.blackTheme}`)
  }

  addSubTasks = () => {
    let values = this.state.subTasks;
    values.push({ name: "", is_completed: false });
    this.setState({ subTasks: values });
  }

  deleteField = (index: number) => {
    let value = this.state.subTasks;
    value.splice(index, 1);
    this.setState({ subTasks: value })
  }

  changeSubTasks = (event: string, indexTask: number) => {
    let newValue = this.state.subTasks;
    this.state.subTasks.forEach((element, index) => {
      if (index === indexTask) {
        newValue[index].name = event
      }
      this.setState({ subTasks: newValue })
    })
  }

  changeLeftButtonStatus = (value1: boolean, value2: boolean, status: string) => {
    this.setState({
      taskStatus: status,
      enableAssignedTask: value1,
      enableImportantTask: value2,
    }, () => this.getTaskLists(this.state.token))
  }

  changeRightButtonStatus = (value1: boolean, value2: boolean, status: string) => {
    this.setState({
      taskStatus1: status,
      enableInProgressTask: value1,
      enableCompletedTask: value2,
    }, () => this.getTaskLists2(this.state.token))
  }

  changeOptionMenu = (event: React.MouseEvent<HTMLElement>, item: ITaskList) => {
    this.setState({
      editAnchor: event.currentTarget,
      selectedTaskId: item.attributes.task.attributes.id
    });
  }

  showAddModal = () => {
    if (this.state.showSubTask) {
      this.setState({ showSubTask: false })
    } else {
      this.setState({
        taskTitle: "",
        taskDescription: "",
        dueDuration: "",
        allocate_type: "all_company",
        subTasks: [{ name: "", is_completed: false }],
        showSubTask: false,
        dropdownTasks: false,
        isVisibleModal: !this.state.isVisibleModal,
        editMode: false,
      });
    }
  };

  openPublishModal = () => {
    this.setState({
      publishModal: !this.state.publishModal
    })
  }

  changeAllocateType = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ allocate_type: event.target.value })
  }

  changeTaskTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ taskTitle: event.target.value })
  }

  changeTaskDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ taskDescription: event.target.value })
  }

  handleInputName = (name: string) => {
    this.setState({ name });
  };

  expandTasksView = () => {
    this.setState({ dropdownTasks: !this.state.dropdownTasks });
  };

  showEditModal = () => {
    let task = this.state.selectedTasks.attributes;
    this.setState({
      editAnchor: null,
      taskTitle: task.title,
      taskDescription: task.description,
      dueDuration: task.due_date ? task.due_date : "",
      subTasks: task.task_lists ? task.task_lists : [{ name: "", is_completed: false }],
      isVisibleModal: !this.state.isVisibleModal,
      editMode: true,
    });
  };

  handleEditSelect = (item: ITaskList) => {
    this.setState({
      id: item.id,
      name: item.attributes.name,
      selectedTasks: item.attributes.task,
      dropdownTasks: false,
    }, () => this.showEditModal());
  };

  handleTasksSelect = (dataId: string) => {
    let newData = this.state.tasksData.map((task: ITask) => {
      if (task.id === dataId) {
        return { ...task, isSelected: !task.isSelected };
      }
      return task;
    });
    this.setState({ tasksData: newData });
  };

  // Function to fetch task list from the API
  getTaskLists = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksListApiEndPoint}?status=${this.state.taskStatus}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to add new task list and send it to API
  addTaskList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const httpBody = {
      "task": {
        "title": this.state.taskTitle,
        "description": this.state.taskDescription,
        "due_date": this.state.dueDuration,
        "priority": true,
        "allocate_type": this.state.allocate_type,
        "task_lists_attributes": this.state.subTasks
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postTaskListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksListApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to edit task list and send it to API
  editTaskList = (tasklistId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const httpBody = {
      "task": {
        "priority": true,
        "title": this.state.taskTitle,
        "due_date": this.state.dueDuration,
        "allocate_type": this.state.allocate_type,
        "description": this.state.taskDescription,
        "task_lists_attributes": this.state.subTasks
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putTaskListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksListApiEndPoint + "/" + `${this.state.selectedTaskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to delete task list and send it to API
  deleteTaskList = (tasklistId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTaskListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksListApiEndPoint + "/" + `${tasklistId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to fetch tasks from the API
  getTasks = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTasksApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTaskLists2 = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage2 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId2 = requestMessage2.messageId;

    requestMessage2.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksListApiEndPoint}?status=${this.state.taskStatus1}`
    );
    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage2.id, requestMessage2);
  };

  getTaskProgress = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage3 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskProgressApiCallId = requestMessage3.messageId;

    requestMessage3.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskProgressApiEndPoint
    );
    requestMessage3.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage3.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage3.id, requestMessage3);
  };

  checkSubtask = (event: CheckboxChangeEvent, itemId: number) => {
    const httpBody = {
      'is_completed': event.target.checked
    }
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putTaskProgressApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.putTasksProgressApiEndPoint + "/" + `${itemId}` + "/mark_progress_task_list"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
