import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Avatar
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ThemeProvider, styled } from "@material-ui/core/styles";
import { darkTheme, lightTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import Loader from "../../../components/src/Loader.web";
import { ToastContainer, toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";
import { INotifications } from "./Service.web";
// Customizable Area End

import DailyScheduleNotificationsController, {
  Props,
  configJSON,
} from "./DailyScheduleNotificationsController.web";

export default class DailyScheduleNotifications extends DailyScheduleNotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  Card = (item: INotifications) => (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={item.attributes.assigned_by.first_name} src={item.attributes.assigned_by.image} />
          <div style={{ marginLeft: '10px' }}>
            <NameField>{item.attributes.assigned_by.first_name || 'Ibrahim M.'}</NameField>
            <DesignationField>{item.attributes.assigned_by.role_id || 'Instructor'}</DesignationField>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: "20px" }}>
          <TitleCourse>{this.notificationTime(item.attributes.assigned_by.created_at)}</TitleCourse>
          <DetailsButton data-test-id="detailsButton" onClick={this.gotoNotification}>Details</DetailsButton>
        </div>
      </div>
      <TitleCourse style={{ marginTop: '8px' }}>Course Assigned</TitleCourse>
      <CourseHeading>Instructor Just Assigned new course to you</CourseHeading>
      <CourseSection>
        <CourseImage src={item.attributes.course.data?.attributes.image} />
        <div style={{ width: '100%' }}>
          <TitleCourse>Course Name</TitleCourse>
          <CourseValue>{item.attributes.course.data?.attributes.course_name}</CourseValue>
        </div>
      </CourseSection>
    </div>
  )
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={this.props.theme ? darkTheme : lightTheme}>
        <Loader loading={this.state.loading} />
        {this.state.notifications?.map((item, index) => (toast(this.Card(item), { toastId: index })))}
        <HeadDiv>
          <ToastContainer
            icon={false}
            position="bottom-right"
            autoClose={false}
            closeOnClick={false}
            draggable={false}
          />
        </HeadDiv >
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const HeadDiv = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  transition: 'all 300ms linear',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  "& .Toastify__toast-container": {
    width: '35%'
  },
  "& .Toastify__close-button": {
    position: 'relative',
    top: 15,
    color: theme.palette.info.light,
    right: 20
  },
  "& .Toastify__toast": {
    borderRadius: '10px',
    background: theme.palette.primary.main
  },
  "& .Toastify__close-button > svg": {
    fill: theme.palette.info.light
  }
}))

const CourseHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '12px',
  color: theme.palette.info.main,
  lineHeight: '18px'
}))

const TitleCourse = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '10px',
  color: theme.palette.info.light
}))

const NameField = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 500,
  color: theme.palette.info.main,
  lineHeight: '18px',
}))

const DesignationField = styled(Typography)(({ theme }) => ({
  fontSize: '11.51px',
  fontWeight: 700,
  color: theme.palette.secondary.light,
  lineHeight: '14.8px',
}))

const DetailsButton = styled(Button)(({ theme }) => ({
  padding: '10px 16px',
  maxWidth: '81px',
  height: '32px',
  border: `1px solid ${theme.palette.info.contrastText}`,
  borderRadius: '30px',
  fontSize: '14px',
  fontFamily: 'Rubik',
  lineHeight: '22px',
  fontWeight: 700,
  color: theme.palette.info.contrastText,
  textAlign: 'left',
  marginLeft: '10px',
  textTransform: 'capitalize',
}))

const CourseSection = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  display: 'flex',
  border: `1px solid ${theme.palette.text.hint}`,
  marginTop: '15px',
  flexDirection: 'row',
  alignItems: 'center'
}))

const CourseImage = styled('img')({
  width: "100%",
  maxHeight: "63px",
  borderRadius: "19px"
})

const CourseValue = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 400,
  color: theme.palette.info.main,
  lineHeight: '18px',
}))
// Customizable Area End
