export const mailIcon = require("../assets/mailIcon.svg");
export const bellIcon = require("../assets/bellIcon.svg");
export const profileImage = require("../assets/profilePic.svg");
export const progressIcon = require("../assets/progress.svg");
export const timeIcon = require("../assets/timeIcon.svg");
export const averageScoreIcon = require("../assets/averageScoreIcon.svg");
export const completedIcon = require("../assets/completedIcon.svg");
export const certificateIcon = require("../assets/certificateIcon.svg");
export const courseOne = require("../assets/courseOne.png");
export const courseTwo = require("../assets/courseTwo.png");
export const courseThree = require("../assets/courseThird.png");
export const arrowView = require("../assets/arrowViewIcon.png");
export const personsIcons = require("../assets/personsIcons.png");
export const chatIcon = require("../assets/chatIcon.png");
export const person1 = require("../assets/person1.png");
export const person2 = require("../assets/person2.png");
export const starRatingIcon = require("../assets/starRatingIcons.png");
export const closeIcon = require("../assets/closeIcon.png");
export const pinIcon = require("../assets/pinIcon.png");
export const sendIcon = require("../assets/sendIcon.png");