import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  breadcrumbs: string[];
  gotoPage: (page: string) => void;
  // Customizable Area End
}
interface Children {
  id: number;
  page: string;
}

interface S {
  // Customizable Area Start
  currentChildren: Children[];
  currentBreadcrumbNavigation: (string | number)[];
  txtInputPageName: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class BreadcrumbNavigationPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDocumentId: string = "";
  getDocumentFetchBreadcrumbData: string = "";
  currentGetBreadData: string[] = [];
  currentPageId: string | number | undefined = Infinity;
  postDocumentId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentChildren: [],
      currentBreadcrumbNavigation: [Infinity],
      txtInputPageName: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJsonSuccess = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    // Customizable Area End
  }

  // web events
  setInputPageName(pageName: string) {
    this.setState({
      txtInputPageName: pageName,
    });
  }

  // Customizable Area Start
  // Customizable Area End
}
